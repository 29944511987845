<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 70vw;" class="q-pa-none">
      <q-card-section class="row">

        <div class="text-h6 text-center">
          {{ $t('Move items') }}
        </div>


        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section class="row" style="max-height: 5vw">

        <form-builder :schema="schema" class="full-width"/>

      </q-card-section>

      <q-card-section>
        <q-table
            class="my-q-table"
            row-key="id"
            :rows="items"
            :columns="cols"
            hide-bottom
            hide-header
            binary-state-sort
        >
          <template v-slot:body="props">
            <labelling-movement-table
                :data="props"
                @removeItem="handleRemoveItem"
                @itemChange="handleItemChange"
            />
          </template>
        </q-table>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            class="q-mr-md"
            size="md"
            no-caps
            unelevated
            @click="save"
        />

        <q-btn
            color="green-6"
            text-color="white"
            class="q-mr-md"
            :label="$t('Move All')"
            size="md"
            no-caps
            :disable="!items"
            unelevated
            @click="saveAll"
        />

        <q-btn
            color="grey-10"
            text-color="grey-10"
            class="q-mr-md"
            :label="$t('Discard')"
            outline
            no-caps
            @click="close"
        />

      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex'
import LabellingMovementTable from '@/apps/app/components/modals/LabellingMovementTable.vue'

export default {
  name: 'LabellingMovementModal',
  emits: ['submit', 'change', 'move-items'],
  components: {
    LabellingMovementTable
  },
  data() {
    return {
      isOpen: false,
      items: [],
      boxes: [],
      currentBox: null,
      selectedBox: null,
      cols: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          name: 'pool',
          align: 'center',
          field: 'pool'
        },
        {
          name: 'total',
          align: 'center',
          field: 'total'
        },
        {
          name: 'quantity',
          align: 'center',
          field: 'quantity'
        },
        {
          name: 'actions',
          align: 'left',
          field: 'actions'
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    schema() {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Source box'),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                value: this.currentBox,
                options: this.boxes,
                required: true,
                disabled: true,
                dense: true,
                customLabel: (row) => {
                  if (!row) {
                    return ''
                  }

                  if (row.priority === 0) {
                    return 'Items pool'
                  }

                  return `Box ${row.priority}`
                },
                onChange: (box) => {
                  this.currentBox = box
                }
              },
              {
                type: 'select',
                label: this.$t('Box number'),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                value: this.selectedBox,
                options: this.getBoxes(),
                required: true,
                dense: true,
                customLabel: (row) => {
                  if (!row) {
                    return ''
                  }

                  if (row.priority === 0) {
                    return 'Items pool'
                  }

                  return `Box ${row.priority}`
                },
                onChange: (box) => {
                  this.selectedBox = box
                }
              }
            ],
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    getBoxes() {
      let boxes = this.boxes;
      if (this.currentBox) {
        boxes = boxes.filter(box => box.id !== this.currentBox.id)
      }

      return boxes
    },
    handleItemChange(item, quantity) {
      this.items.map(e => {
        if (e.id === item.id) {
          e.quantityToMove = quantity
        }
      })
    },
    save() {
      this.items.map(e => {
        if (!e.quantityToMove) {
          e.quantityToMove = 1
        }
      })

      this.$emit('move-items', {
        sourcePlace: this.currentBox,
        targetPlace: this.selectedBox,
        items: this.items,
      })

      this.isOpen = false
    },
    saveAll() {
      this.items.map(e => {
        e.quantityToMove = e.count
      })

      this.save()
    },
    close() {
      this.items = []
      this.boxes = []
      this.currentBox = {}
      this.isOpen = false
    },
    open(items, currentBox = null, boxes = [], selectedBox = null) {
      this.items = items

      this.currentBox = currentBox
      this.boxes = boxes

      if (selectedBox) {
        this.selectedBox = selectedBox
      } else {
        this.selectedBox = boxes[0]
      }

      this.isOpen = true
    },
    handleRemoveItem(item) {
      this.items = this.items.filter(e => e.id !== item.id)
    }
  }
}

</script>