<template>
  <div class="row" style="align-items: center">
    <q-list
        class="col rounded"
    >
      <q-item
          v-for="(place , i) in currentPlaces"
          :key="place.id"
          class="block text-center q-item-no-border full-width"
      >

        <q-card
            class="col no-border"
            flat
            borderless
            v-if="place.type === 'pallet'"
        >
          <q-card-section class="row no-wrap">
            <div class="col align-items-center text-left">
              <q-chip class="q-my-none text-center q-px-lg" size="lg" square :color="colorOfBox(place)">
                  {{ `Pallet N ${i+1}` }}
              </q-chip>
            </div>

            <div class="col-2">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    label="Weight"
                    :value="place.weight || null"
                    :input-label="`Weight (${appOptions.defaultDimensions.weightUnit})`"
                    :update-function="newValue => updatePlaceDimensions('weight', newValue, place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>
              </q-btn-group>
            </div>

            <div class="col-4">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    label="W"
                    :value="place?.dimensions?.x ? place.dimensions.x : null"
                    :input-label="`W (${appOptions.defaultDimensions.dimensionUnit})`"
                    :update-function="newValue => updatePlaceDimensions('x', newValue, place)"
                    :reset-change="checkResetDimensions(place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>

                <editable-input-field
                    label="H"
                    :value="place?.dimensions?.y ? place.dimensions.y : null"
                    :input-label="`H (${appOptions.defaultDimensions.dimensionUnit})`"
                    :update-function="newValue => updatePlaceDimensions('y', newValue, place)"
                    :reset-change="checkResetDimensions(place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>

                <editable-input-field
                    label="L"
                    :value="place?.dimensions?.z ? place.dimensions.z : null"
                    :input-label="`L (${appOptions.defaultDimensions.dimensionUnit})`"
                    :update-function="newValue => updatePlaceDimensions('z', newValue, place)"
                    :reset-change="checkResetDimensions(place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>
              </q-btn-group>
            </div>

            <div class="col-3 text-right">
              <q-btn-group>
                <q-chip
                    class="q-my-none text-center q-px-lg"
                    size="lg"
                    square
                    clickable
                    @click="handleOpen(place)"
                    :icon-right="iconName(place)"
                    :disable="!place || place.items < 0"
                >
                    <q-tooltip>
                        {{ 'Click to expand' }}
                    </q-tooltip>
                </q-chip>

                <q-btn-dropdown
                    size="md"
                    flat
                    round
                    dense
                    no-icon
                    dropdown-icon="more_horiz"
                >
                  <q-list>
                    <q-item>
                      <q-item-section>
                        <q-item-label>
                            {{ `Box ID: ${place.id}` }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </q-btn-group>
            </div>
          </q-card-section>
          <q-card-section v-if="ifOpened(place)">
              <pallet-pool-row :data="place" :deliveryRequest="deliveryRequest.id" />
          </q-card-section>
        </q-card>
      </q-item>

    </q-list>
  </div>
  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="negative"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Delete product')"
              @click="handleRemovePlace()"
          />

          <q-btn
              textarea="center"
              :label="$t('Cancel')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>

  </q-dialog>

  <confirm-modal ref="confirmModal"/>


</template>

<script>


import { mapGetters } from 'vuex'
import ConfirmModal from '@/apps/app/components/confirm-modal/ConfirmModal.vue'
import DRProduct from '@/apps/app/components/products/DRProduct.vue'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'
import CloneBoxesModal from '@/apps/app/components/modals/CloneBoxesModal.vue'
import PalletPoolRow from "@/apps/app/components/delivery-requests/PalletPoolRow.vue";

export default {
  name: 'PalletBoxes',
  components: {
    PalletPoolRow,
    CloneBoxesModal,
    EditableInputField,
    ConfirmModal
  },
  emit: ['change-catch-all', 'change', 'request-move-items', 'delete', 'clone-place'],
  props: {
    scanInput: {
      type: Object,
      default () {
        return null
      }
    },
    places: {
      type: Array,
      default () {
        return []
      }
    },
    poolEnabled: {
      type: Object,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    place: {
      type: Object,
      default () {
        return {}
      }
    },
    deliveryRequest: {
      type: [String, Number],
      default () {
        return null
      }
    },
    itemToMove: {
      type: Object,
      default () {
        return null
      }
    },
  },
  mounted () {
    this.arrangePlaces(this.places)
  },
  watch: {
    scanInput (barcode) {
      if (!barcode.type && this.places && this.places.length >= 1) {
        let place = this.places[0]
        if (this.poolPlace && this.poolPlace.items) {
          place = this.poolPlace
        }

        const string = `(${barcode.value})`
        const found = place.items.filter(e => e.name.includes(string))
        if (found.length > 0) {
          const itemToMove = found[0]

          this.selectedItems.push(itemToMove)

          return
        }
      }
    },
    places: {
      handler (value) {
        this.arrangePlaces(this.places)
      },
      deep: true
    },
    poolEnabled: {
      handler (value) {
        if (!value) {
          this.openedBoxes.push(this.currentPlaces[0])
        }
      },
    }
  },
  data () {
    return {
      items: [],
      currentPlaces: null,
      dimensions: {},
      openedBoxes: [],
      height: null,
      width: null,
      length: null,
      weight: null,
      areYouSure: false,
      fallbackImage: './assets/img/fallback-image/package.png',
      selectedItems: [],
      placeUpdates: [],
      imageZoomDialog: false,
      zoomedSrc: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    highlightSubstring () {
      return (text, item) => {
        if (!this.scanInput) {
          return text
        }

        if (this.scanInput.type === '') {
          const value = `(${this.scanInput.value})`
          const regex = new RegExp(value, 'gi')
          if (this.itemToMove && this.itemToMove.id === item.id) {
            return text.replace(regex, (match) => {
              return `<span class="bg-positive">${match}</span>`

            })
          } else if (this.itemToMove) {
            return text.replace(regex, (match) => {
              return `<span class="bg-yellow">${match}</span>`
            })
          } else {
            return text
          }
        } else {
          return text
        }
      }
    },
  },
  methods: {
    arrangePlaces (places) {
      this.currentPlaces = places.filter(e=>{
        if(e.type === 'pallet') {
          return e
        }
      })

      if (this.currentPlaces?.length > 0) {
        this.currentPlaces.sort((a, b) => b.priority - a.priority)

        this.openedBoxes.push(this.currentPlaces[0])
      }
    },
    iconName (place) {
      if (this.openedBoxes.filter(e => e.id === place.id).length > 0) {
        return 'expand_less'
      } else {
        return 'expand_more'
      }
    },
    handleSelectItems (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true

          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems.push(item)
      }
    },
    colorOfBox (place) {
      if (place.weight && place.dimensions && place.dimensions.x && place.dimensions.y && place.dimensions.z) {
        return 'green-6'
      }
      return 'orange-6'
    },
    handleCloseItemModal () {
      this.$refs.labellingMovement.close()
    },
    handleCloseEditItem (value) {
      this.$eventBus.update('catchAll', {
        'catchAll': value
      })

      this.$emit('change-catch-all', value)
    },
    checkResetDimensions (place) {
      return this.placeUpdates[place.id] && this.placeUpdates[place.id].reset
    },
    updatePlaceDimensions (state, value, place) {
      if (Number(value) <= 0) {
        return
      }

      if (state === 'weight') {
        place.weight = value
        return this.$service.deliveryServicePlace.save({
          weight: value
        }, place.id).then((updatedPlace) => {
          const index = this.places.findIndex(place => {
            return place.id === updatedPlace.id
          })

          if (index !== -1) {
            // If the item exists in the array, replace it with the updated version
            this.places[index] = updatedPlace
          }
        })
      } else {
        if (!place.dimensions) {
          place.dimensions = {}
        }

        if (!this.placeUpdates[place.id] || this.placeUpdates[place.id].reset) {
          this.placeUpdates[place.id] = {
            dimensions: {}
          }
        }

        this.placeUpdates[place.id].dimensions[state] = value

        // Check if all dimensions (x, y, z) are defined and then save them
        if (this.placeUpdates[place.id].dimensions.x !== undefined && this.placeUpdates[place.id].dimensions.y !== undefined && this.placeUpdates[place.id].dimensions.z !== undefined) {
          return this.$service.deliveryServicePlace.save(this.placeUpdates[place.id], place.id)
            .then((updatedPlace) => {
              const index = this.places.findIndex(place => {
                return place.id === updatedPlace.id
              })

              if (index !== -1) {
                // If the item exists in the array, replace it with the updated version
                this.places[index] = updatedPlace
              }

              delete this.placeUpdates[place.id].dimensions
              this.placeUpdates[place.id].reset = true
            })
        } else {
          return Promise.reject('The sizes are not complete')
        }
      }
    },
    ifOpened (place) {
      return this.openedBoxes.filter(e => e.id === place.id).length > 0
    },
    handleOpen (place) {
      if (this.openedBoxes.filter(e => e.id === place.id).length > 0) {
        this.openedBoxes = this.openedBoxes.filter(e => e.id !== place.id)
      } else {
        this.openedBoxes.push(place)

      }
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    disableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: false
      })
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    }
  },
}

</script>