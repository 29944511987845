<template>
  <q-dialog v-model="isOpenItemModal">
    <q-card style="min-width: 65vw;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t('Product') }}
        </div>

        <q-btn
            class="q-ml-md"
            color="light-blue-9"
            :label="$t('Show list')"
            :disable="disabled"
            no-caps
            @click="handleOpenList"
        />

        <q-btn
            class="q-ml-md"
            color="dark"
            text-color="white"
            :label="$t('EAV')"
            no-caps
            @click="handleEAV"
        />

        <q-space/>

        <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
            @click="close"
        />
      </q-card-section>

      <q-card-section>
        <div class="row q-pa-md">
          <div class="col-12 col-sm-5">
            <img
                style="height: 100%; width: 100%; object-fit: contain;"
                :src="item.image || fallbackImage"
                @error="onImageLoadFailure"
            >
          </div>

          <div class="col-12 col-sm-7">
            <form-builder :schema="schema"/>
          </div>
        </div>
      </q-card-section>

      <q-card-actions class="justify-end border-top">
        <q-btn
            color="dark"
            text-color="white"
            size="sm"
            :label="$t('Close')"
            no-caps
            unelevated
            @click="close"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            :label="$t('Save')"
            no-caps
            unelevated
            @click="save"
        />
      </q-card-actions>
    </q-card>

    <q-dialog v-model="isOpenOffers">
      <q-card style="min-width: 70vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Templates') }}
          </div>

          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
              @click="close"
          />
        </q-card-section>

        <q-card-section>
          <product-template @submit="handleOffer"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-dialog>
    <eav-modal ref="eavModal" @submit="handleEAVSubmit"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ProductTemplate from './ProductTemplate.vue'
import EavModal from "@/apps/app/components/modals/EavModal.vue";

export default {
  name: 'DRProduct',
  emits: ['submit', 'change-catch'],
  components: {
    EavModal,
    ProductTemplate
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    delivery: {
      type: Number,
      default () {
        return
      }
    }
  },
  data () {
    return {
      isOpenOffers: false,
      isOpenItemModal: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      item: {
        barcodes: [],
        count: 1,
        dimensions: {
          x: 0,
          y: 0,
          z: 0
        },
        estimatedCost: 0,
        image: null,
        name: '',
        payment: 0,
        state: 'active',
        weight: 0
      },
      itemChanges: {},
      place: {},
      oldPlace: {},
      copiedId: null
    }
  },
  computed: {
    ...mapGetters([
      'deliveryRequest'
    ]),
    schema () {
      return {
        groups: [
          {
            tyleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Box'),
                field: 'place',
                value: this.place,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `Box id #${row.id}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  let query = {}
                  if(!this.deliveryRequest) {
                    query = {
                      per_page: 25,
                      page,
                      search,
                      filter: [
                        { type: 'eq', field: 'deliveryRequest', value: this.delivery}
                      ]
                    }
                  } else {
                    query = {
                      per_page: 25,
                      page,
                      search,
                      filter: [
                        { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest.id}
                      ]
                    }
                  }


                  return this.$service.deliveryServicePlace.getAll(query)
                },
                onChange: place => {
                  this.oldPlace = this.place
                  this.place = place

                  this.itemChanges['place'] = place.id
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'copy',
                    icon: 'content_copy',
                    color: 'teal',
                    size: 'lg',
                    disabled: this.disabled,
                    onClick: () => {
                      this.copiedId = this.item.id
                      this.item.id = null

                      this.copy()
                    }
                  }
                ]
              },
              {
                type: 'input',
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                inputType: 'text',
                field: 'extId',
                label: this.$t('ExtId'),
                value: this.item.extId,
                required: true,
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.extId = value

                  this.itemChanges['extId'] = value
                }
              },
              {
                type: 'input',
                wrapperStyleClasses: 'col-12 col-md-8 q-pa-xs',
                inputType: 'text',
                value: this.item.name,
                field: 'name',
                label: this.$t('Name'),
                required: true,
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.name = value

                  this.itemChanges['name'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.payment,
                field: 'payment',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Payment'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.payment = value

                  this.itemChanges['payment'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.estimatedCost,
                field: 'estimatedCost',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Estimated Cost'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.estimatedCost = value

                  this.itemChanges['estimatedCost'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.count,
                field: 'count',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Count'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.count = value

                  this.itemChanges['count'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.weight,
                field: 'weight',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Weight'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.weight = value

                  this.itemChanges['weight'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.dimensions.x,
                field: 'x',
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                label: this.$t('Width'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.dimensions.x = value

                  this.itemChanges['dimensions']['x'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.dimensions.y,
                field: 'y',
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                label: this.$t('Height'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.dimensions.y = value

                  this.itemChanges['dimensions']['y'] = value
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.item.dimensions.z,
                field: 'z',
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                label: this.$t('Length'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.dimensions.z = value

                  this.itemChanges['dimensions']['z'] = value
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'image',
                value: this.item.image,
                wrapperStyleClasses: 'col-12 col-md-12 q-pa-xs',
                label: this.$t('Image'),
                disabled: this.disabled,
                onChange: (value) => {
                  this.item.image = value

                  this.itemChanges['image'] = value
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleOpenList () {
      this.isOpenOffers = true
    },
    handleOffer (offer) {
      this.item = {
        ...this.item,
        count: offer.count,
        name: offer.name,
        image: offer.image,
        eav: offer.eav || this.item.eav,
        weight: offer.weight || this.item.weight,
        payment: offer.price || this.item.payment,
        dimensions: !offer.dimensions || typeof offer.dimensions === 'string'
            ? this.item.dimensions
            : offer.dimensions
      }
      this.isOpenOffers = false
    },
    getItem (item) {
      if (!item) {
        return {
          barcodes: [],
          count: 1,
          eav: [],
          dimensions: {
            x: 0,
            y: 0,
            z: 0
          },
          estimatedCost: 0,
          image: null,
          name: '',
          payment: 0,
          state: 'active',
          weight: 0
        }
      }

      return item
    },
    close () {
      this.isOpenItemModal = false
      this.$emit('change-catch', true)
    },
    open (item, place) {
      this.item = this.getItem(item)

      if (!this.item.dimensions || typeof this.item.dimensions === 'string') {
        this.item.dimensions = {
          x: 0,
          y: 0,
          z: 0
        }
      }

      if (place) {
        this.place = place
      }

      this.isOpenItemModal = true
      this.$emit('change-catch', false)
    },
    save () {
      if (this.itemChanges) {
        delete this.itemChanges.deliveryRequest

        this.$service.deliveryServicePlaceItems.save(this.itemChanges, this.item.id)

        this.itemChanges = {}
        this.$emit('submit', this.item , this.place , this.oldPlace)
      } else {
        if (!this.item.extId) {
          this.addErrorNotification('Please add extId!')
          return
        }

        this.$emit('change-catch', true)
      }
    },
    copy () {
      this.itemChanges = {}
      Object.entries(this.item).forEach(([key, value]) => {
        if (value instanceof Object && value.id) {
          this.itemChanges[key] = value.id
        } else {
          this.itemChanges[key] = value
        }
      })

      this.itemChanges['place'] = this.place.id
      delete this.itemChanges.id
      delete this.itemChanges.created
      delete this.itemChanges.updated

      this.itemChanges['deliveryRequest'] = this.place._embedded?.deliveryRequest?.id

      this.$service.deliveryServicePlaceItems.save(this.itemChanges).then(item => {
        this.item.id = this.copiedId
        this.itemChanges = {}

        this.$emit('submit', item , this.place , this.oldPlace , true)
      })
      return this.item

    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleEAV () {
      const options = {
        entityClass: this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest_Item
      }
      this.$refs.eavModal.open(this.item.eav, options)
    },
    handleEAVSubmit (eav) {
      this.item.eav = eav
      this.itemChanges['eav'] = eav
    },
  }
}
</script>

