<template>
  <div>
    <div
        class="row q-px-md q-py-xs items-center"
    >
      <div class="col-sm-3 col-12 q-py-xs row items-center">
        <q-btn
            icon="print"
            size="sm"
            class="q-mr-sm"
            :label="isConnectedPrinterBg ? 'online' : 'offline'"
            :color="isConnectedPrinterBg ? 'positive' : 'negative'"
            @click="handlePrintSettingsOpen"
        />
      </div>

      <div class="col-sm-5 col-12 q-py-xs text-center">
        <q-btn-group push>
          <q-btn
              color="dark"
              text-color="white"
              size="sm"
              style="max-width: 150px;"
              :label="$t('Shipping request')"
              :disable="scanType === 'delivery-request'"
              no-caps
              @click="setScanType('delivery-request')"
          />

          <q-btn
              color="dark"
              text-color="white"
              size="sm"
              style="max-width: 120px;"
              :disable="scanType === 'measurements'"
              :label="$t('Measurements')"
              no-caps
              @click="setScanType('measurements')"
          />
        </q-btn-group>
      </div>

      <div class="col-sm-4 col-12 q-py-xs text-right">

        <q-btn
            v-if="this.queue && this.queue.settings && this.queue.settings['load-requests-automatically']"
            color="dark"
            text-color="white"
            class="q-mr-sm"
            size="sm"
            :label="$t('Filters')"
            :disable="isLoading"
            no-caps
            @click="handleFilterOpenClose"
        />

        <q-btn
            v-if="this.queue && this.queue.settings && this.queue.settings['load-requests-automatically']"
            color="dark"
            text-color="white"
            class="q-mr-sm"
            size="sm"
            :icon-right="isOpenQueue ? 'expand_less' : 'expand_more'"
            :label="$t('Queue')"
            no-caps
            @click="handleQueueOpenClose"
        />

        <q-btn
            color="warning"
            text-color="dark"
            class="q-mr-sm"
            size="sm"
            :label="$t('Reset')"
            no-caps
            @click="handleReset"
        />

        <q-btn
            color="dark"
            text-color="white"
            size="sm"
            :label="$t('Settings')"
            no-caps
            @click="handleOpenCloseSettingsModal"
        />
      </div>
    </div>

    <filters-collapse
        :is-open="isOpenFilter"
        :options="{
          defaultFilter: filter,
          fields: activatedFields,
          values: {
            states: deliveryStatuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="handleFilterClose"
    />

    <q-slide-transition>
      <div style="overflow: auto;">
        <q-list
            v-if="isOpenQueue"
            class="relative"
            style="min-width: 1000px; height: calc(100vh - 100px);"
            bordered
            separator
        >
          <measuring-row
              v-for="item in deliveryRequests.items"
              :key="item.id"
              :item="item"
              :delivery-request="deliveryRequest"
              :disabled="isLoading"
              @dblclick="handleDeliveryRequest"
          />
        </q-list>
      </div>
    </q-slide-transition>

    <div
        v-if="deliveryRequest"
    >
      <div style="overflow: auto;">
        <div
            class="row border q-ma-md rounded"
            style="min-width: 900px;"
        >
          <div class="row col-12 items-center q-pa-sm border-bottom">
            <div class="col-2">
              <h6 class="q-ma-none text-subtitle1 text-bold">
                {{ $t('Client Id') + ': ' + (deliveryRequest.clientExtId || deliveryRequest.extId) }}
              </h6>
            </div>
            <div class="col-3">
              <p class="q-my-none q-mx-sm text-subtitle1">
                {{
                  $t('Shipment') + ': '
                }}
                <span @click="openDR(deliveryRequest.id)" class="doc-link cursor-pointer">{{
                    deliveryRequest.id
                  }}</span>
                {{
                  '/' + $moment(deliveryRequest.created.date).format(appOptions.formats.date)
                }}
              </p>
            </div>
            <div class="col-2">
              <p class="q-my-none q-mx-sm text-subtitle1">
                <span v-if="deliveryRequest._embedded && deliveryRequest._embedded.order">
                  {{ $t('Order') + ': ' }}
                </span>

                <label v-if="deliveryRequest._embedded && deliveryRequest._embedded.order"
                       @click="openOrder(deliveryRequest._embedded.order)" class="doc-link cursor-pointer">
                  {{ deliveryRequest._embedded.order.id }}
                </label>
              </p>
            </div>
            <div class="col">
              <q-input type="date" dense v-model="settings.default.sendDate"/>
            </div>
            <div class="col-2 text-right">
              <q-btn-dropdown
                  color="dark"
                  size="sm"
                  icon="picture_as_pdf"
                  :label="$t('Print')"
              >
                <q-list>
                  <q-item
                      v-close-popup
                      clickable
                      v-if="deliveryRequest?._embedded?.order"
                      @click="handlePrintOrderLabel"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Order label') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="printLabelByRequest"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Request label') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="handlePrint"
                      :disable="isLabelDisabled"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Print labels') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="isLabelDisabled"
                      @click="printLabel({ mode: 'show' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Show labels') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="!isAmazonLtl"
                      v-if="isAmazonLtl"
                      @click="printLabel({ mode: 'show', accept: 'application/boxes+pdf' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Amz boxes') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="!isAmazonLtl"
                      v-if="isAmazonLtl"
                      @click="printLabel({ mode: 'show', accept: 'application/pallets+pdf' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Amz pallets') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="!isAmazonLtl"
                      v-if="isAmazonLtl"
                      @click="printLabel({ mode: 'show', accept: 'application/bol+pdf' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Amz BOL') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="handleSendWaiting"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Send to the waiting area') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="openAreYouSure"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Cancel Task') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </div>

          <div class="row col-12 q-pa-sm border-bottom text-center"
               :class="{ 'bg-amber-6': deliveryRequest._embedded.sender.state !== 'blocked', 'bg-red-5': deliveryRequest._embedded.sender.state === 'blocked'}"
               v-if="deliveryRequest._embedded.sender.state === 'blocked'">
            <h6 class="q-ma-none text-subtitle1 text-bold">
              {{ $t('Sender status') + ':' }} {{ (deliveryRequest._embedded.sender.state) }}
            </h6>
          </div>

          <div class="q-pa-sm text-center row items-center justify-center">
            <div v-if="deliveryRequest._embedded.deliveryService">

              <img
                  v-if="deliveryRequest._embedded.deliveryService.logo"
                  :src="checkLogo(deliveryRequest)"
                  :title="deliveryRequest._embedded.deliveryService.name"
                  style="width: 100px; height: 20px; object-fit: contain;"
              >

              <h6
                  v-else
                  class="q-my-none"
              >
                {{ deliveryRequest._embedded.deliveryService.name }}
              </h6>
            </div>

            <div v-else>
              {{ $t('No shipping carrier') }}
            </div>
          </div>

          <div
              class="q-pa-sm"
              style="min-width: 200px; max-width: 300px; word-break: break-word;"
          >
            <q-item-label class="text-h6 q-mb-sm">
              {{ $t('Sender') }}
            </q-item-label>

            <div>
              <q-btn
                  color="primary"
                  text-color="white"
                  icon="calculate"
                  size="sm"
                  class="q-mr-sm q-my-xs"
                  :label="$t('Rate calculator')"
                  no-caps
                  @click="openCalculator"
              />
            </div>

            <q-item-label
                lines="1"
                class="text-caption"
            >
              <div v-if="deliveryRequest._embedded.sender">
                {{ deliveryRequest._embedded.sender.name }}
              </div>

              <div v-else>
                {{ $t('No shipping carrier') }}
              </div>
            </q-item-label>

            <q-item-label
                lines="1"
                class="text-caption"
            >
              <div v-if="deliveryRequest._embedded.rate">
                {{ deliveryRequest._embedded.rate.name }}
              </div>

              <div v-else>
                {{ $t('No shipping rate') }}
              </div>
            </q-item-label>

            <q-item-label
                class="text-caption"
                lines="1"
            >
              <div v-if="deliveryRequest._embedded.servicePoint">
                {{ deliveryRequest._embedded.servicePoint.name }}
              </div>

              <div v-else>
                {{ $t('No pick up point') }}
              </div>
            </q-item-label>
          </div>

          <div
              class="q-pa-sm"
              style="min-width: 200px; max-width: 300px; word-break: break-word; white-space: normal !important;"
          >
            <q-item-label class="text-h6 q-mb-sm">
              {{ $t('Shipping label') }}
            </q-item-label>

            <div class="text-left" v-if="task?.state !== 'closed' && isDimensionsEnabled">
              <q-btn
                  color="positive"
                  text-color="white"
                  icon="print"
                  class="q-mr-sm q-my-xs"
                  size="sm"
                  :label="$t('Create')"
                  :disable="isLoading"
                  no-caps
                  @click="handleSend(deliveryRequest)"
              />
            </div>

            <q-item-label
                v-if="deliveryRequest.trackingNumber"
                lines="1"
            >
              <span class="doc-link cursor-pointer"
                    @click="doCopy(deliveryRequest.trackingNumber)">
                {{ deliveryRequest.trackingNumber }}
                </span>
            </q-item-label>

            <small v-if="task">
              {{ $t('Task #') + ' ' + task.id }}
              <q-badge outline color="primary">{{ task.state }}</q-badge>
            </small>

            <q-item-label
                v-if="deliveryRequest._embedded.recipientLocality"
                class="text-caption"
                lines="5"
            >
              <span>{{ deliveryRequest._embedded.recipientLocality.name }}</span>
              <span class="q-mr-xs" v-if="deliveryRequest._embedded.recipientAddress.postcode">, {{
                  deliveryRequest._embedded.recipientAddress.postcode
                }}</span>
            </q-item-label>

          </div>

          <div
              class="col q-pa-sm"
              style="min-width: 100px; max-height: 180px; overflow-y: auto;"
          >
            <q-item-label class="text-h6 q-mb-sm">
              {{ $t('Error message') }}
            </q-item-label>

            <div
                v-if="deliveryRequest.deliveryServiceStatus"
                class="row"
            >
              <q-icon
                  name="error"
                  color="negative"
                  size="1.5rem"
              />

              <div class="col q-ml-sm text-negative">
                {{ $t(deliveryRequest.deliveryServiceStatus) }}
              </div>
            </div>

            <div
                v-if="task?.state === 'upload_error' || task?.state === 'error'"
                class="row"
            >
              <q-icon
                  name="error"
                  color="negative"
                  size="1.5rem"
              />

              <div class="col q-ml-sm text-negative">
                {{ task.exportResult }}
              </div>
            </div>

            <q-item-label
                v-else
                class="text-caption"
                lines="1"
            >
              {{ $t('No errors') }}
            </q-item-label>
          </div>

          <div
              class="col q-pa-sm"
              v-if="ifChecklist"
              style="width: 100px; max-height: 180px; overflow-y: auto;"
          >
            <q-item-label class="text-h6 q-mb-sm">
              {{ $t('Checklist') }}
            </q-item-label>
            <q-btn
                color="primary"
                text-color="white"
                size="sm"
                class="q-mr-sm q-my-xs"
                :label="$t('Click to open')"
                no-caps
                @click="openDropdown = true"
                @mouseleave="openDropdown = false"
            />
            <q-popup-proxy
                v-model="openDropdown"
                anchor="top middle"
                @mouseenter="openDropdown = true"
                @mouseleave="openDropdown = false"
            >
              <q-stepper
                  v-model="step"
                  vertical
                  color="primary"
                  animated
              >
                <q-step
                    :name="1"
                    title="Packed"
                    icon="edit"
                    :done="step > 1"
                >
                  Information about label is uploaded to Amazon and we are waiting for the measurements.

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('packed', 2)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="2"
                    title="Measuring"
                    icon="edit"
                    :done="step > 2"
                >
                  We are measuring places.

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('measuring', 3)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="3"
                    title="Measured"
                    icon="edit"
                    :done="step > 3"
                >
                  Places are measured, we are uploading transport details.
                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('measured', 4)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="4"
                    title="Prepared"
                    icon="edit"
                    :done="step > 4"
                >
                  We are uploading feed content (information about places and items).

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('prepared', 5)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="5"
                    title="Calculating"
                    icon="edit"
                    :done="step > 5"
                >
                  We are estimating the delivery price.

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('calculating', 6)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="6"
                    title="Calculated"
                    icon="edit"
                    :done="step > 6"
                >
                  We are confirming the delivery price.

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('calculated', 7)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="7"
                    title="Exported"
                    icon="edit"
                    :done="step > 7"
                >
                  We are confirming the shipment.

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('exported', 8)" color="primary" label="Continue"/>
                  </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="8"
                    title="Closed"
                    icon="edit"
                    :done="doneStep"
                >
                  We are closing the shipment.

                  <q-stepper-navigation>
                    <q-btn @click="handleEditTask('closed', 8)" color="primary" label="Finish"/>
                    <q-btn @click="handleEditTask('cancel', 8)" color="negative" label="Cancel" class="q-ml-sm"/>
                  </q-stepper-navigation>
                </q-step>
              </q-stepper>
            </q-popup-proxy>

          </div>

          <div class="q-ml-auto q-pa-md row items-center">
          </div>
        </div>
      </div>

    </div>

    <div
        v-if="isDimensionsEnabled"
        class="q-py-sm text-center"
        :class="`${!getPlaceSizesCount() ? 'bg-success' : 'bg-orange-5'}`"
        style="font-size: 18px; border: 1px solid #000;"
    >
      <strong v-if="this.placeSize?.id && getPlaceSizesCount()">
        {{
          $t('Place') + ' (' + this.placeSize.id + '): ' + getPlaceSizesCount() + '/' + deliveryRequest?.places?.length
        }}
      </strong>

      <strong v-if="!this.placeSize?.id || !getPlaceSizesCount()">
        {{ $t('Places') + ': ' + deliveryRequest?.places?.length }}
      </strong>
      -
      <span style="font-size: 16px;">
        {{ $t('Weight') + ': ' + overallWeight }} <small>{{ appOptions.defaultDimensions.weightUnit }}</small>
      </span>
    </div>

    <div class="q-pa-sm text-center" v-if="this.deliveryRequest && !isDimensionsEnabled">
      <div v-if="isLabelGenerated">
        <img
            :src="labelGeneratedImage"
            class="fit"
            style="object-fit: contain;max-height:150px;"
        >

        <q-item-label class="q-mb-sm q-mb-lg">
          {{ $t('Shipping label being generated since') }} {{ updateTimeDifferenceToText }}
        </q-item-label>
      </div>

      <q-btn
          color="positive"
          text-color="white"
          icon="print"
          class="q-mr-sm q-my-xs"
          size="lg"
          :label="createShippingLabelButtonText"
          :disable="isCreateLabelEnabled"
          no-caps
          @click="handleSend(deliveryRequest)"
          v-if="isCreateLabelButtonEnabled"
      />

      <q-btn
          color="positive"
          text-color="white"
          icon="print"
          class="q-mr-sm q-my-xs"
          size="lg"
          :label="$t('Print shipping label')"
          :disable="isLoading || isLabelDisabled"
          no-caps
          @click="handlePrint(isLabelDisabled)"
          v-if="task?.state === 'closed'"
      />

      <!--      <q-btn-->
      <!--          color="dark"-->
      <!--          text-color="white"-->
      <!--          size="lg"-->
      <!--          class="q-my-xs"-->
      <!--          :label="`${$t('Load next shipment')} (${deliveryRequests.totalItems} ${$t('left')})`"-->
      <!--          :disable="isLoading"-->
      <!--          no-caps-->
      <!--          @click="handleLoadNextShipment"-->
      <!--      />-->
    </div>

    <div class="border rounded q-ma-sm" v-if="!this.deliveryRequest">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
        >
          {{ this.isLoading ? $t('Delivery request is being loaded now.') : $t('Please scan the delivery request!') }}
        </div>
      </div>
    </div>

    <form
        v-if="isDimensionsEnabled"
        @submit="handleDimensionsSubmit"
    >
      <div
          v-if="isMultiPlaceGood && deliveryRequest"
          class="row bg-dark items-stretch"
      >
        <div class="col-8 row q-pt-md items-center">
          <div class="col-sm-3 q-pl-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                step="any"
                :model-value="placeSize.weight"
                :label="$t('Weight') + ', ' + appOptions.defaultDimensions.weightUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimmensions($event, '')"
            />
          </div>

          <div class="col-sm-3 q-pl-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                step="any"
                :model-value="placeSize.dimensions.x"
                :label="$t('Width') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimmensions($event, 'x')"
            />
          </div>

          <div class="col-sm-3 q-pl-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                step="any"
                :model-value="placeSize.dimensions.y"
                :label="$t('Height') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimmensions($event, 'y')"
            />
          </div>

          <div class="col-sm-3 q-px-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                step="any"
                :model-value="placeSize.dimensions.z"
                :label="$t('Length') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimmensions($event, 'z')"
            />
          </div>
        </div>

        <div
            class="col-4 q-pt-md"
            :class="dimensionsBackground"
        >
          <form-builder ref="formBuilder" :schema="schema"/>
        </div>
      </div>

      <div
          v-else
          class="row bg-dark items-stretch"
      >
        <div class="col-12 col-sm-8 row q-pt-md items-center">
          <div class="col-12 col-sm-3 q-px-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                class="full-width"
                :model-value="weight"
                :label="$t('Weight') + ', ' + appOptions.defaultDimensions.weightUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="setDimension($event, 'weight')"
            />
          </div>

          <div class="col-12 col-sm-3 q-px-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                :model-value="width"
                :label="$t('Width') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="setDimension($event, 'width')"
            />
          </div>

          <div class="col-12 col-sm-3 q-px-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                :model-value="height"
                :label="$t('Height') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="setDimension($event, 'height')"
            />
          </div>

          <div class="col-12 col-sm-3 q-px-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                dark
                :model-value="length"
                :label="$t('Length') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="setDimension($event, 'length')"
            />
          </div>

          <div v-if="weightMessage" class="col-12 text-red text-subtitle2 text-center q-pb-sm">
            {{ $t('Max weight is') + ': ' + settings.maxWeight }}
          </div>
        </div>

        <div
            class="col-sm-4 col-12 q-pt-md "
            :class="dimensionsBackground"
        >
          <form-builder ref="formBuilder" :schema="schema"/>
        </div>
      </div>

      <!-- To work submit event -->
      <button style="display: none;"/>
    </form>

    <div v-if="this.queue && this.queue.settings && this.queue.settings['load-requests-automatically']"
         class="q-py-sm q-px-md border-bottom row no-wrap">
      <div class="col-10">
        <barcode-input
            :disabled="isLoad || isLoading"
            :out-focused="isFocused"
            :catchAllFalse="catchAll"
            has-max-width
            @barcode="handleBarcode"
        />
      </div>
      <div class="col-2 justify-end" style="width: 200px">

        <q-btn
            color="dark"
            text-color="white"
            size="md"
            class="q-ma-xs q-ml-lg justify-end"
            :disabled="isLoad"
            :label="loadNextDeliveryRequestLabel"
            no-caps
            style="width: 200px"
            @click="handleLoadNextShipment"
        />
      </div>
    </div>

    <div v-else class="q-py-sm q-px-md border-bottom row">
      <barcode-input
          :disabled="isLoad"
          :out-focused="isFocused"
          :catchAllFalse="catchAll"
          has-max-width
          @barcode="handleBarcode"
      />
    </div>

    <div
        v-if="settings?.isEnabledDeliveryRequestTable"
        class="bg-white q-pa-sm"
    >
      <delivery-requests-list/>
    </div>
    <div class="q-pa-md q-gutter-sm">
      <div class="row justify-center">
        <q-btn-toggle
            color="grey-7"
            no-caps
            toggle-color="dark"
            text-color="white"
            rounded
            v-model="slide"
            :disable="this.isLoading"
            :options="[
            { label: 'Labeling', value: 'alpha' },
            { label: 'LTL/FTL', value: 'pallet'},
            { label: 'Order Services', value: 'order' },
            { label: 'Shipment Services', value: 'deliveryRequest' }
          ]"
        />
      </div>
      <q-carousel
          v-if="deliveryRequest"
          v-model="slide"
          swipeable
          animated
          :padding="true"
          control-color="dark"
          class=" rounded-borders"
          style="height: fit-content"
      >
        <q-carousel-slide name="alpha">
          <q-separator/>

          <d-r-boxing :places="places" :places-stats="boxesStats" :place-items-pool="placeItemsPool"
                      :is-loading-finished="isFinished"
                      :scan-input="scanInput"
                      :disabled="handleDisabledFields" @moved="handleBoxDimensions" @handleRefreshBoxes="handleMoved"/>
        </q-carousel-slide>
        z
        <q-carousel-slide name="pallet">
          <q-separator/>

          <d-r-pallets :places="places" :is-loading-finished="isFinished" :scan-input="scanInput"/>
        </q-carousel-slide>

        <q-carousel-slide name="deliveryRequest">

          <div class="row justify-center no-border q-py-sm" style="border: none !important;">
            <div class=" col-6 text-h5  block text-center q-no-border">
              <strong>{{ $t('Shipment') }}</strong>
            </div>

          </div>

          <q-separator/>

          <div v-if="deliveryRequest" class="row justify-center no-border" style="border: none !important;">

            <div class="col-5 block text-center q-no-border">
              <supplies-table-labelling
                  flat
                  :ext-filter="extFilter"
                  :deliveryRequest="deliveryRequest.id"
                  :services="isServices"
                  @on-dblclick="saveSupplies"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServices"
              />
            </div>

            <q-separator vertical/>

            <div class="col-6 block text-center q-no-border">
              <added-supplies-table
                  flat
                  :deliveryRequest="deliveryRequest.id"
                  :services="isServices"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServices"
              />
            </div>
          </div>
        </q-carousel-slide>
        <q-carousel-slide name="order">

          <div class="row justify-center no-border q-py-sm" style="border: none !important;">
            <div class=" col-6 text-h5  block text-center q-no-border">
              <strong>{{ $t('Order') }}</strong>
            </div>

          </div>

          <q-separator/>

          <div v-if="deliveryRequest && deliveryRequest._embedded && deliveryRequest._embedded.order"
               class="row justify-center no-border" style="border: none !important;">

            <div class="col-5 block text-center q-no-border">
              <supplies-table-labelling-order
                  flat
                  :ext-filter="extFilter"
                  :order="deliveryRequest._embedded.order.id"
                  :services="isServicesOrder"
                  @on-dblclick="saveOrderSupplies"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServicesOrder"
              />
            </div>

            <q-separator vertical/>

            <div class="col-6 block text-center q-no-border">
              <added-supplies-table-order
                  flat
                  :order="deliveryRequest._embedded.order.id"
                  :services="isServicesOrder"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServicesOrder"
              />
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
    </div>
    <div>

    </div>

    <div
        class="fixed-bottom-right text-right"
        style="bottom: 10px; right: 20px;"
    >
      <q-btn
          v-if="deliveryRequest?._embedded?.order"
          class="q-mb-sm block q-ml-auto fab"
          fab
          size="lg"
          icon-right="output"
          color="light-blue-9"
          :label="$t('Order')"
          label-position="left"
          dark
          @click="handleOpenOrder(deliveryRequest?._embedded?.order)"
      />
    </div>


    <printer-settings-modal
        ref="printerSettingsModal"
        @close="handlePrinterSettingsClose"
    />
    <q-dialog v-model="isOpenCalculator">
      <q-card style="min-width: 40vw; max-width: 60%">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Calculator') }}
          </div>

          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section>
          <d-s-calculator
              ref="calculator"
              :delivery-request="deliveryRequest"
              :locality="deliveryRequest?._embedded?.recipientLocality? deliveryRequest._embedded.recipientLocality: ''"
              :weight="Number(deliveryRequest.weight) ? deliveryRequest.weight : boxDimensions?.weight ? boxDimensions.weight : 1"
              :width="deliveryRequest.dimensions && deliveryRequest.dimensions.x ? deliveryRequest.dimensions.x : boxDimensions?.dimensions && boxDimensions.dimensions.x ? boxDimensions.dimensions.x : 1"
              :height="deliveryRequest.dimensions && deliveryRequest.dimensions.y ? deliveryRequest.dimensions.y : boxDimensions?.dimensions && boxDimensions.dimensions.y ? boxDimensions.dimensions.y : 1"
              :length="deliveryRequest.dimensions && deliveryRequest.dimensions.z ? deliveryRequest.dimensions.z : boxDimensions?.dimensions && boxDimensions.dimensions.z ? boxDimensions.dimensions.z : 1"
              @rate="updateRate"
              @update-dimensions="updateDimension"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
        v-model="settingsModal"
        @hide="handleOpenCloseSettingsModal(true)"
    >
      <q-card style="min-width: 50vw;">
        <q-card-section class="row items-center q-pb-none">
          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <packaging-settings @close="handleOpenCloseSettingsModal"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="areYouSure">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Cancel task')"
                @click="handleCancelTask"
            />

            <q-btn
                textarea="center"
                :label="$t('Discard')"
                @click="closeAreYouSure"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="noSizesCheck">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row items-center q-pb-none">
          <div>
            {{ $t('You do not have sizes do you want to proceed with default ones ?') }}
          </div>
          <div>
            {{ `Weight: ${this.settings.default.weight} (${appOptions.defaultDimensions.weightUnit})` }}
            <br>
            {{ `Width: ${this.settings.default.width} (${appOptions.defaultDimensions.dimensionUnit})` }}
            <br>
            {{ `Height: ${this.settings.default.height} (${appOptions.defaultDimensions.dimensionUnit})` }}
            <br>
            {{ `Length: ${this.settings.default.length} (${appOptions.defaultDimensions.dimensionUnit})` }}
          </div>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Use Default')"
                @click="useDefaultSettings"
            />

            <q-btn
                textarea="center"
                :label="$t('Cancel')"
                @click="noSizesCheck = false"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>

    <filter-modal
        ref="filterModal"
        :filter-fields="filterFields"
        @submit="handleConsumptionFilter"
    />

    <delivery-request-services-modal ref="deliveryRequestModal"/>
    <delivery-request-services-modal-side ref="deliveryRequestModalSide"/>

    <labelling-movement-modal ref="labellingMovement" @change="handleItemSubmit" @submit="handleMoved"/>

    <create-item-modal ref="createItemModal" @submit="handleSubmitWaiting"/>

    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Utils
import _ from 'lodash'

// Components
import BarcodeInput from './../../components/barcode-input/BarcodeInput'
import PackagingSettings from './../../components/packaging-settings/PackagingSettings'
import DeliveryRequestsList from './../../components/delivery-requests-list/DRList'
import ConfirmModal from '../../components/confirm-modal/ConfirmModal.vue'
import PrinterSettingsModal from '../../components/modals/PrinterSettingsModal.vue'
import DRProducts from '../../components/products/DRProducts.vue'
import FilterModal from '../../components/modals/FilterModal.vue'
import { HALApiService } from '@/apps/app/services'
import { defineAsyncComponent } from 'vue'
import { doCopy } from './../../utils/copy-utils'
import DeliveryRequestServicesModal from '../../components/modals/DeliveryRequestServicesModal.vue'
import DeliveryRequestServicesModalSide from '../../components/modals/DelveryRequestServicesModalSide.vue'
import DRBoxing from '@/apps/app/components/delivery-requests/DRBoxing.vue'
import SuppliesTableLabelling from '@/apps/app/components/tables/SuppliesTableLabelling.vue'
import AddedSuppliesTable from '@/apps/app/components/tables/AddedSuppliesTable.vue'
import SuppliesTableLabellingOrder from '@/apps/app/components/tables/SuppliesTableLablellingOrder.vue'
import AddedSuppliesTableOrder from '@/apps/app/components/tables/AddedSuppliesTableOrder.vue'
import LabellingMovementModal from '@/apps/app/components/modals/LabellingMovementModal.vue'
import MeasuringRow from '@/apps/app/components/products/MeasuringRow.vue'
import FiltersCollapse from '@/apps/app/components/filters/FilterCollapse.vue'
import deliveryRequestStatusMatrix from '@/apps/app/config/DeliveryRequestMatrix'
import DRPallets from '@/apps/app/components/delivery-requests/DRPallets.vue'
import { transliterate as tr } from 'transliteration'
import CreateItemModal from '@/apps/app/components/modals/CreateItemModal.vue'

// Used to generate place package with sizes
function createPlaceSize (place) {
  return {
    id: place?.id,
    weight: null,
    dimensions: {
      x: null,
      y: null,
      z: null
    }
  }
}

/**
 * Create Date
 *
 * @param {object} date
 *
 * @returns {String}
 */
function createDate (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

export default {
  name: 'Measuring',
  emits: ['finish-executing'],
  components: {
    CreateItemModal,
    DRPallets,
    DRBoxing,
    FiltersCollapse, MeasuringRow,
    LabellingMovementModal,
    AddedSuppliesTableOrder,
    SuppliesTableLabellingOrder,
    AddedSuppliesTable,
    SuppliesTableLabelling,
    DeliveryRequestServicesModalSide,
    DeliveryRequestServicesModal,
    DRProducts,
    BarcodeInput,
    DSCalculator: defineAsyncComponent(() => import('../../components/delivery-services/Calculator.vue')),
    PackagingSettings,
    DeliveryRequestsList,
    ConfirmModal,
    PrinterSettingsModal,
    FilterModal
  },
  props: {
    outsideDeliveryRequest: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      rates: [],
      slide: 'alpha',
      isLoadingFinished: false,
      services: [],
      servicesOrder: [],
      extFilter: [],
      isBeta: false,
      carrier: null,
      settingsModal: false,
      isFocused: false,
      isConnectedPrinterBg: false,
      weightMessage: false,
      loadedConsumptions: [],
      isFirstAction: true,
      isMultiPlaceGood: true,
      placesToUse: [],
      placeItemsPool: [],
      indexOfSelected: 0,
      selectedPlace: null,
      placeSize: createPlaceSize(),
      placeSizes: [],
      boxesStats: {
        count: 1,
        page: 1,
        total: 1,
      },
      isLoad: false,
      isMeasured: true,
      printerSub: null,
      consumptionFilter: [],
      catchAll: true,
      isOpenCalculator: false,
      areYouSure: false,
      isServicesOpen: false,
      measuredDR: [],
      loadedConsumptionsData: {},
      deliveryRequests: {
        items: [],
        page: 0,
        totalPages: 1,
        totalItems: 0
      },
      filter: [
        { field: 'state', type: 'in', values: ['pre_processing', 'error', 'pending'] },
        { field: 'sendDate', type: 'gte', value: createDate(new Date()) }
      ],
      filterFields: [
        'sender',
        'created.from',
        'created.to',
        'deliveryService'
      ],
      isOpenQueue: false,
      alreadyLoaded: false,
      isLoading: false,
      isLabelGenerated: false,
      isOpenFilter: false,
      deliveryStatuses: deliveryRequestStatusMatrix,
      selectedPlaceWithFlag: false,
      activatedFields: [
        'id',
        'extId',
        'clientExtId',
        'recipientName',
        'recipientPhone',
        'paymentState',
        'state',
        'created.to',
        'created.from',
        'sendDate.to',
        'sendDate.from',
        'integration',
        'sender',
        'settings'
      ],
      currentConsumptionFilter: null,
      validStatusCodes: [503, 409, 423],
      secondsSinceTimestamp: null,
      taskCreateUnlockStates: ['upload_error', 'error', 'cancel'],
      scanInput: null,
      step: 1,
      openDropdown: false,
      doneStep: false,
      noSizesCheck: false,
      boxDimensions: null,
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'settings',
      'task',
      'queue',
      'timestamp',
      'mode',
      'deliveryRequest',
      'places',
      'deliveryRequestsItemsNumber',
      'scanType',
      'consumptions',
      'consumptionsPage',
      'consumptionsTotalPages',
      'lockedDeliveryRequests',
      'width',
      'height',
      'length',
      'weight'
    ]),
    isCreateLabelEnabled () {
      if (this.isLoading || !this.isCreateLabelButtonEnabled || this.ifCreateDisabled || ((!this.places || this.places.length === 0) && this.isFinished)) {
        return true
      }
      return false
    },
    isFinished () {
      return this.isLoadingFinished
    },
    isServices () {
      return this.services
    },
    isServicesOrder () {
      return this.servicesOrder
    },
    ifChecklist () {
      if (this.deliveryRequest?._embedded?.source?.handler === 'amazon') {
        return true
      }
      return true
    },
    updateTimeDifferenceToText () {
      let seconds = this.secondsSinceTimestamp

      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60

      if (minutes === 0) {
        return `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`
      } else {
        return `${minutes} minute${minutes !== 1 ? 's' : ''} and ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`
      }
    },
    ifCreateDisabled () {
      if (this.places) {
        const sizes = this.calculateOverallSizes(this.places)

        if (this.queue?.settings && this.queue?.settings['trust-incoming-measurements']) {
          if (!sizes.dimensions.z || !sizes.dimensions.x || !sizes.dimensions.y || !sizes.weight) {
            return true
          }
        }
      }

      return false
    },
    isCreateLabelButtonEnabled () {
      if (!this.task || this.taskCreateUnlockStates.includes(this.task?.state)) {
        return true
      }

      if (this.isLabelGenerated && this.secondsSinceTimestamp > 90) {
        return true
      }

      if (!this.isLabelDisabled) {
        return false
      }

      return !this.isLabelGenerated
    },
    loadNextDeliveryRequestLabel () {
      return `Load next shipment (${this.deliveryRequests.totalItems} left)`
    },
    ifConsumptionDisabled () {
      if (!this.deliveryRequest || !!this.deliveryRequest.sentBy) {
        return true
      }

      return false
    },
    dimensionsBackground () {
      return this.settings.consumption
          ? 'bg-teal'
          : 'bg-orange-5'
    },
    schema3 () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            id: 'drForm',
            styleClasses: 'row',
            fields: [
              {
                ref: 'rate',
                type: 'multiselect',
                label: this.$t('Shipping rate'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'rate',
                value: this.deliveryRequest && this.deliveryRequest._embedded.rate,
                required: true,
                disabled: !this.deliveryRequest ||
                    !this.deliveryRequest._embedded.sender,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row._embedded?.deliveryService?.logo) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  query['order-by'] = [
                    { type: 'field', field: 'deliveryService', direction: 'desc' },
                    { type: 'field', field: 'name', direction: 'desc' }
                  ]

                  if (this.deliveryRequest._embedded.deliveryService) {
                    query.filter.push({
                      type: 'eq',
                      field: 'deliveryServices',
                      value: this.deliveryRequest._embedded.deliveryService.id
                    })
                  } else if (this.isEnabledSenderFilter && this.deliveryServicesIDs && this.deliveryServicesIDs.length > 0) {
                    query.filter.push({
                      type: 'in',
                      field: 'deliveryServices',
                      values: this.deliveryServicesIDs
                    })
                  }

                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (rate) => {
                  const types = ['service_point', 'self_service_point', 'simple']
                  this.isDisabledServicePoints = !types.includes((rate || {}).type)

                  this.updateDeliveryRequestEmbedded({ rate, servicePoint: null })
                  this.saveDeliveryRequest()
                }
              },
            ]
          },
        ]
      }
    },
    schema2 () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            id: 'drForm',
            styleClasses: 'row',
            fields: [
              {
                ref: 'deliveryService',
                type: 'multiselect',
                label: this.$t('Shipping carrier'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'deliveryService',
                value: this.deliveryRequest && this.deliveryRequest._embedded.deliveryService,
                required: true,
                disabled: !this.deliveryRequest || !this.deliveryRequest._embedded.sender,
                customLabel (row) {
                  let ratePrice = 0
                  if (row && typeof row === 'object') {
                    this.rates.forEach(e => {
                      if (e.id === row.id) {
                        ratePrice = e.deliveryPrice
                      }
                    })
                    if (ratePrice) {
                      return `${row.name} (${row.id})  Price: ${ratePrice}`
                    }
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row.logo) {
                    return ''
                  }
                  if (row.logo.includes('http')) {
                    return `${row.logo}`
                  } else {
                    return `${this.appOptions.defaultServer}${row.logo}`
                  }

                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.country && this.isEnabledSenderFilter) {
                    query.filter.push({ type: 'eq', field: 'country', value: this.country })
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: (deliveryService) => {
                  this.carrier = deliveryService
                  this.updateDeliveryRequestEmbedded({ deliveryService, rate: null, servicePoint: null })
                  this.saveDeliveryRequest()
                },
              }
            ]
          },
        ]
      }
    },
    schema () {
      return {
        groups: [
          {
            id: 'form',
            fields: [
              {
                ref: 'consumption',
                type: 'multiselect',
                label: this.$t('Choose the shipping manifest'),
                field: 'consumption',
                disabled: this.ifConsumptionDisabled,
                wrapperStyleClasses: 'q-px-sm',
                value: this.settings.consumption,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.id + '/' + row.created.date.split(' ')[0] + ' (' + row._embedded.deliveryService.name + ')'
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'created', direction: 'desc' }
                    ],
                    filter: this.consumptionFilter,
                  }

                  return this.$service.deliveryServiceConsumption.getAll(query)
                },
                onFocus: () => {
                  this.onFocus()
                },
                onFocusOut: () => {
                  this.onFocusOut()
                },
                onChange: (consumption) => {
                  this.updateSettings({ consumption })

                  if (!consumption) {
                    this.isFirstAction = true
                  }
                },
                btnStyleClasses: 'q-px-xs',
                buttons: [
                  {
                    id: 'filter',
                    icon: 'filter_alt',
                    color: this.consumptionFilter.length > 0
                        ? 'teal'
                        : 'dark',
                    size: 'lg',
                    onClick: () => {
                      this.$refs.filterModal.show(this.consumptionFilter)
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    // Calculate overall weight when dr is mutliplace
    overallWeight () {
      if (!this.isMultiPlaceGood) {
        return this.deliveryRequest.weight || 0
      }

      return this.placeSizes.reduce((acc, place) => {
        return acc + Number(place.weight || 0)
      }, Number(this.placeSize?.weight || 0))
    },
    isLabelDisabled () {
      if (this.deliveryRequest._embedded.rate.type === 'ltl' && this.task?.state === 'measuring') {
        return false
      }

      return this.task?.state != 'closed'
    },
    isAmazonLtl () {
      if (this.deliveryRequest._embedded.rate.type === 'ltl' && this.deliveryRequest?._embedded?.source?.handler === 'amazon') {
        return true
      }

      return false
    },
    labelGeneratedImage () {
      if (this.secondsSinceTimestamp > 55) {
        return 'assets/app/label-generated-chaos.png'
      }

      return 'assets/app/label-generated.png'
    },
    isDimensionsEnabled () {
      if (this.deliveryRequest && this.queue?.settings && this.queue?.settings['show-measurements-input']) {
        return true
      }

      return false
    },
    createShippingLabelButtonText () {
      if (this.task) {
        switch (this.task.state) {
          case 'preparing':
            return this.$t('Check content feed upload status')

          case 'calculating':
          case 'calculated':
            return this.$t('Check calculating status')
        }
      }

      return this.$t('Create shipping label')
    },
    handleDisabledFields () {
      if (this.queue?.settings && this.queue.settings['disable-packing-interface']) {
        return true
      }
      return false
    },
  },
  watch: {
    // Check for invalid value
    slide (newSlide) {
      // Check if the active slide is the "order" slide
      if (newSlide === 'order') {
        this.servicesOrder = this.servicesOrder
      }
      if (newSlide = 'deliveryRequest') {
        this.services = this.services
      }
    },
    weight (newValue) {
      this.weightMessage = Number(this.settings.maxWeight) < newValue && this.settings.weightControl
    },
    consumptions () {
      this.handleChange('consumptions')
    },
    queue (newValue) {
      if (newValue && newValue.settings && newValue.settings['load-requests-automatically'] && !this.alreadyLoaded) {
        this.loadDeliveryRequests(true)
        this.alreadyLoaded = true
      }

      if (this.$route.params.deliveryRequest && !this.isLoading && (!this.deliveryRequest || parseInt(this.deliveryRequest.id) !== parseInt(this.$route.params.deliveryRequest))) {
        this.handleBarcode({ value: this.$route.params.deliveryRequest, type: 'DS/DR' })
      }
    },
    deliveryRequest: {
      handler (newValue, oldValue) {
        if (newValue && (!oldValue || newValue.id !== oldValue.id)) {
          if (this.deliveryRequest && this.queue?.settings && this.queue.settings['generate-shipping-manifest']) {
            this.autoGenerateManifest()
          }
        }

        if (newValue) {
          if (newValue.places && Array.isArray(newValue.places)) {
            let places = _.cloneDeep(newValue.places)
            this.isLoadingFinished = true
            this.setPlaces(Object.values(places))
          } else {
            this.handleMoved()
          }
        }

        this.servicesOrder = []
        this.services = []
      },
      deep: true
    },
    task (taskValue) {
      let states = ['closed', 'measuring']
      let combinedStates = [...states, ...this.taskCreateUnlockStates]
      this.checkTaskState()
      if (taskValue && combinedStates.includes(this.task.state)) {
        this.isLabelGenerated = false
      } else {
        this.isLabelGenerated = true
      }

      setInterval(() => { this.updateTimeDifference() }, 1000)
    }
  },
  mounted () {
    if (!this.queue && this.$route.params.id) {
      const query = {}

      if (this.$route.params.id && parseInt(this.$route.params.id) !== 0) {
        query.filter = [{ type: 'eq', field: 'id', value: this.$route.params.id }]
      }

      return this.$service.preprocessingQueue.getAll(query)
          .then(({ items }) => {
            this.queues = items

            if (items[0]) {
              this.setQueue(items[0])

              this.updateSettings(this.kebabToCamel(this.queue.settings))
            }
          })
    } else if (this.$route.params.deliveryRequest) {
      this.handleBarcode({ value: this.$route.params.deliveryRequest, type: 'DS/DR' })
    }

    this.$service.printer.turnOnReconnect()
    this.printerSub = this.$service.printer._printer.subscribe('stateChange', (isConnected) => {
      this.isConnectedPrinterBg = isConnected
    })

    if (this.outsideDeliveryRequest) {
      let value = this.outsideDeliveryRequest
      if (this.outsideDeliveryRequest.id) {
        value = this.outsideDeliveryRequest.id
      }

      this.handleBarcode({ value: value, type: 'DS/DR' })
    }

    if (this.deliveryRequest?._embedded?.deliveryService) {
      this.carrier = this.deliveryRequest._embedded.deliveryService
    }

    if (this.queue && this.queue.settings && this.queue.settings['load-requests-automatically']) {
      this.loadDeliveryRequests(true)
    }

    if (this.deliveryRequest && this.queue?.settings && this.queue.settings['generate-shipping-manifest']) {
      this.autoGenerateManifest()
    } else {
      this.updateSettings({ consumption: null })
    }
  },
  unmounted () {
    if (this.deliveryRequest) {
      this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)

      this.setDeliveryRequest(null)
    }

    this.$service.printer.turnOffReconnect()
    this.printerSub?.unsubscribe()
  },
  methods: {
    ...mapActions([
      'printLabelByRequest',
      'printLabel',
      'loadDeliveryRequests',
      'loadDeliveryRequestByBarcode',
      'executeForm',
      'loadConsumptions',
      'saveDeliveryRequest',
      'loadDeliveryRequest',
    ]),
    ...mapMutations([
      'setScanType',
      'setMode',
      'setQueue',
      'setDeliveryRequest',
      'setPlaces',
      'setWidth',
      'setHeight',
      'setLength',
      'setWeight',
      'addWarningNotification',
      'updateDeliveryRequestEmbedded',
      'updateSettings',
      'addErrorNotification',
      'setInterceptAutoClose',
      'updateDeliveryRequest',
      'addNotification'
    ]),
    checkTaskState () {
      if (this.task) {
        switch (this.task.state) {
          case 'new':
            this.step = 1
            break
          case 'packaging':
            this.step = 1
            break
          case 'packed':
            this.step = 2
            break
          case 'measuring':
            this.step = 3
            break
          case 'upload_error':
            this.step = 3
            break
          case 'error':
            this.step = 3
            break
          case 'measured' :
            this.step = 4
            break
          case 'prepared' :
            this.step = 5
            break
          case 'calculating':
            this.step = 6
            break
          case 'calculated':
            this.step = 7
            break
          case 'exported':
            this.step = 7
            break
          case 'closed':
            this.step = 8
            this.doneStep = true
            break
        }
      }
    },
    updateTimeDifference () {
      const timestamp = new Date(this.task?.updated?.date + ' ' + this.task?.updated?.timezone)
      if (!isNaN(timestamp)) {
        const currentTime = new Date()
        this.secondsSinceTimestamp = Math.floor((currentTime - timestamp) / 1000)
      } else {
        this.secondsSinceTimestamp = null
      }
    },
    kebabToCamel (obj) {
      if (obj && typeof obj === 'object') {
        const newObj = {}
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const camelKey = key.replace(/-([a-z])/g, (match, group) => group.toUpperCase())
            newObj[camelKey] = obj[key]
          }
        }
        return newObj
      } else {
        return obj
      }
    },
    autoGenerateManifest () {
      let sendDate = this.getSendDate(this.deliveryRequest)

      const query = {
        per_page: 25,
        filter: [
          { type: 'eq', field: 'state', value: 'new' },
          { type: 'eq', field: 'sender', value: this.deliveryRequest._embedded.sender.id },
          { type: 'eq', field: 'deliveryService', value: this.deliveryRequest._embedded.deliveryService.id },
          { type: 'eq', field: 'shippingDate', value: sendDate }
        ],
      }

      if (this.currentConsumptionFilter != query) {
        return this.$service.deliveryServiceConsumption.getAll(query)
            .then(({ items }) => {
              if (items.length === 0) {
                const deliveryService = (this.deliveryRequest._embedded.deliveryService || {}).id
                const sender = (this.deliveryRequest._embedded.sender || {}).id
                const shippingDate = this.getSendDate(this.deliveryRequest)
                this.createNewConsumption({ sender, deliveryService, shippingDate }, query)
                    .then(consumption => {
                      this.updateSettings({ consumption })
                    })
                    // .then(() => {
                    //   this.deliveryRequest = {
                    //     ...this.deliveryRequest,
                    //     state: 'sent'
                    //   }
                    //
                    //   // this.measuredDR = [this.deliveryRequest.id, ...this.measuredDR]
                    //   // this.deliveryRequests = {
                    //   //   ...this.deliveryRequests,
                    //   //   items: this.deliveryRequests.items.filter(x => x.id !== this.deliveryRequest.id)
                    //   // }
                    //
                    //   // this.$emit('submit', this.deliveryRequest)
                    // })
                    .catch(error => {
                      this.deliveryRequest = { ...this.deliveryRequest, deliveryServiceStatus: error.message }
                      // this.deliveryRequests = {
                      //   ...this.deliveryRequests,
                      //   items: [
                      //     this.deliveryRequest,
                      //     ...this.deliveryRequests.items.filter(x => x.id !== this.deliveryRequest.id)
                      //   ]
                      // }
                    })
                    .finally(() => {
                      this.isLoading = false
                    })

                this.currentConsumptionFilter = query
              } else if (items.length === 1) {
                const consumption = items[0]

                this.updateSettings({ consumption })

                this.currentConsumptionFilter = query
              } else {
                this.addErrorNotification('Several consumptions returned')
              }

              return items
            })
      }
    },
    handleOpenOrder () {
      const backParam = this.$route.query.back

      this.setDeliveryRequest(null)

      if (backParam) {
        this.$router.push(backParam)
      }
    },
    handleDeliveryRequest (item) {
      this.handleBarcode({ raw: item.id, value: item.id, type: 'skip' })
    },
    handleFiltersSubmit (filter) {
      this.handleFilterClose()

      this.filter = filter
      this.deliveryRequests = {
        ...this.deliveryRequests,
        page: 0,
        totalPages: 1
      }

      return this.loadDeliveryRequests()
          .then(result => {
            this.deliveryRequests = result
            this.loadDeliveryRequest(result.items[0].id)

            if (this.deliveryRequest) {
              this.addNotification('Shipment is loaded!')
            }
          })
    },
    handleFilterClose () {
      this.isOpenFilter = false
    },
    handleFilterOpenClose () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleQueueOpenClose () {
      this.isOpenQueue = !this.isOpenQueue
    },
    createNewConsumption (data = {}, result) {
      const consumption = {
        ...data,
        comment: 'automatic created'
      }

      if (result) {
        consumption.result = result
      }

      return this.$service.deliveryServiceConsumption.save(consumption)
          .then(item => {
            this.loadedConsumptions.push(item)
            return item
          })
    },
    getSendDate (deliveryReqeust) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      let sendDate = deliveryReqeust?.sendDate

      if (sendDate?.date) {
        const [datePart, timePart] = sendDate.date.split(' ')

        const [year, month, day] = datePart.split('-').map(Number)
        const [hour, minute, second] = timePart.split(':').map(Number)

        sendDate = new Date(year, month - 1, day, hour, minute, second)
        sendDate.setHours(0, 0, 0, 0)
      }

      if (sendDate < today) {
        sendDate = null
      }

      if (!sendDate && this.settings.default?.sendDate) {
        const today = new Date()
        const tomorrow = new Date()

        tomorrow.setDate(today.getDate() + 1)

        sendDate = new Date(this.settings.default.sendDate)
      }

      if (sendDate < today) {
        sendDate = today
      }

      return sendDate.toDateString()
    },
    getSize (item) {
      const sizes = item.places.reduce((acc, place) => {
        const placeSize = place.items.reduce((placeAcc, item) => {
          placeAcc.weight += Number(item.weight) || 0

          const itemDimensions = !item.dimensions || typeof item.dimensions === 'string'
              ? { x: 0, y: 0, z: 0 }
              : item.dimensions

          placeAcc.dimensions.x += itemDimensions.x
          placeAcc.dimensions.y += itemDimensions.y

          if (itemDimensions.z > placeAcc.dimensions.z) {
            placeAcc.dimensions.z = itemDimensions.z
          }

          return placeAcc
        }, { weight: 0, dimensions: { x: 0, y: 0, z: 0 } })

        acc.weight += placeSize.weight
        acc.dimensions.x += placeSize.dimensions.x
        acc.dimensions.y += placeSize.dimensions.y

        if (placeSize.dimensions.z > acc.dimensions.z) {
          acc.dimensions.z = placeSize.dimensions.z
        }

        return acc
      }, { weight: 0, dimensions: { x: 0, y: 0, z: 0 } })

      sizes.weight = this.getSizeValue(sizes.weight)
      sizes.dimensions.x = this.getSizeValue(sizes.dimensions.x)
      sizes.dimensions.y = this.getSizeValue(sizes.dimensions.y)
      sizes.dimensions.z = this.getSizeValue(sizes.dimensions.z)

      return sizes
    },
    getSizeValue (value) {
      return value <= 0
          ? 100
          : Number(value)
    },
    loadConsumptionsMulty (deliveryRequests) {
      const entities = deliveryRequests.reduce((acc, entity) => {
        if (entity._embedded && entity._embedded.sender && !acc.senders.includes(entity._embedded.sender.id)) {
          acc.senders.push(entity._embedded.sender.id)
        }

        if (entity._embedded && entity._embedded.deliveryService && !acc.deliveryServices.includes(entity._embedded.deliveryService.id)) {
          acc.deliveryServices.push(entity._embedded.deliveryService.id)
        }

        return acc
      }, { senders: [], deliveryServices: [] })

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'gte', field: 'created', value: createDate(new Date()) },
          { type: 'eq', field: 'state', value: 'new' },
          { type: 'in', field: 'sender', values: entities.senders },
          { type: 'in', field: 'deliveryService', values: entities.deliveryServices },
        ]
      }
      if (this.deliveryRequest && this.deliveryRequest.sendDate && this.deliveryRequest.sendDate.date) {
        query.filter.push({
          type: 'eq',
          field: 'shippingDate',
          value: this.deliveryRequest.sendDate.date.split(' ')[0]
        })
      }
      const params = JSON.stringify(query.filter)

      if (this.loadedConsumptionsData === params) {
        return
      }
      return this.$service.deliveryServiceConsumption.getAll(query)
          .then(result => {
            this.loadedConsumptions = result.items
            this.loadedConsumptionsData = params
            return result
          })
    },
    findItem () {
      const index = this.deliveryRequests.items.findIndex(dr => this.deliveryRequest.id === dr.id)
      return this.deliveryRequests.items.slice(index + 1).find(dr => !this.lockedDeliveryRequests.includes(dr.id))
    },
    handleLoadNextShipment () {
      if (!this.deliveryRequest) {
        return
      }

      return Promise.resolve(true)
          .then(() => {
            return this.measuredDR.includes(this.deliveryRequest.id)
                ? Promise.resolve(true)
                : this.$refs.confirmModal.show({ description: this.$t('The label was not printed! Skip the shipment?') })
          })
          .then(isOk => {
            if (!isOk) {
              return
            }

            const item = this.findItem()

            if (item) {
              this.measuredDR = []

              if (this.deliveryRequest) {
                this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
              }

              this.handleBarcode({ raw: item.id, value: item.id, type: 'skip' })
              this.$channels.user.publish('openDeliveryRequest', this.deliveryRequest.id)
              this.addNotification('Shipment is loaded!')
              return
            }

            if (this.deliveryRequests.page < this.deliveryRequests.totalPages) {
              this.loadDeliveryRequests()
                  .then(result => {
                    this.measuredDR = []

                    if (this.deliveryRequest) {
                      this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
                    }

                    this.deliveryRequests = result
                    this.handleBarcode({ raw: result.items[0].id, value: result.items[0].id, type: 'skip' })

                    if (this.deliveryRequest) {
                      this.$channels.user.publish('openDeliveryRequest', this.deliveryRequest.id)
                      this.addNotification('Shipment is loaded!')
                    }
                  })
            }
          })
    },
    handleIsServices () {
      this.isServicesOpen = !this.isServicesOpen
      const defaultData = {
        state: 'active',
        deliveryRequest: this.deliveryRequest.id,
      }

      this.$refs.deliveryRequestModalSide.open(this.deliveryRequest.id, this.deliveryRequest?._embedded?.order ? this.deliveryRequest._embedded.order.id : null, defaultData)
      this.catchAll = false
    },
    handleIsBeta (value) {
      this.isBeta = value
    },
    handleBoxDimensions (dimensions) {
      this.boxDimensions = dimensions
    },
    handleMoved () {
      const query = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest?.id },
          { type: 'eq', field: 'type', value: 'simple' }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ],
        page: this.boxesStats.page,
        'per_page': 250
      }

      const queryPool = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest?.id },
          { type: 'eq', field: 'type', value: 'pool' }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ],
        'per_page': 250
      }

      return this.$service.deliveryServicePlace.getAll(queryPool)
          .then(({ items }) => {
            this.placeItemsPool = items

            this.$service.deliveryServicePlace.getAll(query)
                .then((result) => {
                  this.isLoadingFinished = true

                  this.boxesStats.count = result.totalItems
                  this.boxesStats.page = result.page
                  this.boxesStats.total = result.totalPages

                  this.handlePlaceChange(result.items)
                })
          })
    },
    handleRemove (place, item) {
      let updatedPlace = place

      const places = this.deliveryRequest.places
          .reduce((acc, p) => {
            if (p.id === place.id) {
              p.items = p.items.filter(i => i.id !== item.id)
              updatedPlace = p

              if (p.items.length <= 0) {
                return acc
              }
            }

            acc.push(p)
            return acc
          }, [])

      this.$service.deliveryServicePlaceItems.save({ state: 'deleted' }, item.id)
      return this.savePlace(updatedPlace)
          .then(() => {
            this.handlePlaceChange(places)
          })
    },
    savePlace (place) {
      if (place.items.length <= 0) {
        return this.$service.deliveryServicePlace.save({ state: 'deleted' }, place.id)
      }

      const items = place.items.map(i => {
        const item = this.createNewItem(i)
        item.place = place.id
        return item
      })

      return this.$service.deliveryServicePlace.save({ deliveryRequest: place._embedded.deliveryRequest.id }, place.id)
    },
    createNewItem (item) {
      const blockedFields = ['_links', 'id', '_embedded', 'created', 'updated', 'price']

      const newItem = Object.keys(item).reduce((c, key) => {
        if (blockedFields.includes(key)) {
          return c
        }

        if (item[key] === undefined || item[key] === null) {
          return c
        }

        c[key] = item[key]
        return c
      }, {})

      if (!newItem.dimensions || typeof newItem.dimensions === 'string') {
        newItem.dimensions = {
          x: 1,
          y: 1,
          z: 1
        }
      }

      return newItem
    },
    updateDimension (item) {
      this.handlePlaceMeasure({
        type: 'db',
        value: `${item.weight}/${item.dimensions.x}/${item.dimensions.y}/${item.dimensions.z}`,
        raw: `${item.weight}/${item.dimensions.x}/${item.dimensions.y}/${item.dimensions.z}`
      })
      this.isOpenCalculator = false
    },
    openCalculator () {
      this.isOpenCalculator = true
    },
    updateRate (rate, isDimensions) {
      const types = ['service_point', 'self_service_point', 'simple']
      this.isDisabledServicePoints = !types.includes((rate || {}).type)

      this.updateDeliveryRequestEmbedded({
        rate,
        deliveryService: rate.deliveryService,
        servicePoint: null
      })

      if (rate.raw) {
        this.updateDeliveryRequest({
          eav: {
            ...this.deliveryRequest?.eav,
            'delivery-services-calculation-result': rate.raw
          }
        })
      }

      this.saveDeliveryRequest()
          .then(() => {
            if (this.deliveryRequest?._embedded?.deliveryService) {
              this.carrier = this.deliveryRequest._embedded.deliveryService
            }
          })
      // if (!isDimensions) {
      //   this.isOpenCalculator = false
      // }
    },
    loadDeliveryRequests (ifMounted = false) {
      this.isLoading = true

      let sendDate

      const newFilters = this.filter.filter(x => {
        if (x.field === 'sendDate') {
          sendDate = x
          return false
        }

        return true
      })

      const orx = {
        type: 'orx',
        conditions: [
          { type: 'isnull', field: 'sendDate' }
        ],
        where: 'or'
      }

      if (sendDate) {
        orx.conditions.push(sendDate)
        newFilters.unshift(orx)
      }

      const query = {
        page: this.deliveryRequests.page >= this.deliveryRequests.totalPages
            ? this.deliveryRequests.page
            : this.deliveryRequests.page + 1,
        per_page: 25,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ],
        filter: newFilters
      }

      if (this.search && this.search[this.search.length - 1] !== '*' && this.search[this.search.length - 2] !== ':' && !query.search.includes('%')) {
        const end = this.search[this.search.length - 1] === ':'
            ? '*'
            : ':*'

        query.search = this.search + end
      } else if (query.search.includes('%')) {
        query.search = this.search
      }

      return this.$service.deliveryRequest.getAll(query)
          .then(result => {
            return this.loadConsumptionsMulty(result.items)
                .then(() => {
                  if (ifMounted) {
                    this.deliveryRequests = result
                    this.handleBarcode({ raw: result.items[0].id, value: result.items[0].id, type: 'skip' })
                    // this.loadDeliveryRequest(result.items[0].id)
                  }
                  return result
                })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    loadItems () {
      if (this.deliveryRequest && this.deliveryRequest.dimensions) {
        let query = {
          debug: !!this.appOptions.debug,
          clearCache: !!this.appOptions.debug,
          weight: this.deliveryRequest.weight || this.appOptions.defaultDimensions.weight || 1,
          width: this.deliveryRequest?.dimensions.x || this.appOptions?.defaultDimensions?.dimensions.x || 1,
          height: this.deliveryRequest?.dimensions.y || this.appOptions?.defaultDimensions?.dimensions.y || 1,
          length: this.deliveryRequest?.dimensions.z || this.appOptions?.defaultDimensions?.dimensions.z || 1,
          divisor: 5000
        }

        if (this.appOptions.calculator && this.appOptions.calculator.deliveryServices) {
          query.deliveryServices = this.appOptions.calculator.deliveryServices
        }

        return HALApiService.get(`/delivery-services/calculator/${this.deliveryRequest.id}`, query)
            .then(data => {
              this.rates = data.rates
            })
      } else if (this.deliveryRequest && !this.deliveryRequest.dimensions) {
        let query = {
          debug: !!this.appOptions.debug,
          clearCache: !!this.appOptions.debug,
          weight: this.deliveryRequest.weight || this.appOptions.defaultDimensions.weight || 1,
          width: this.appOptions.defaultDimensions.dimensions.x || 1,
          height: this.appOptions.defaultDimensions.dimensions.y || 1,
          length: this.appOptions.defaultDimensions.dimensions.z || 1,
          divisor: 5000
        }

        if (this.appOptions.calculator && this.appOptions.calculator.deliveryServices) {
          query.deliveryServices = this.appOptions.calculator.deliveryServices
        }

        return HALApiService.get(`/delivery-services/calculator/${this.deliveryRequest.id}`, query)
            .then(data => {
              this.rates = data.rates
            })
      }
    },
    handleConsumptionFilter (filter) {
      this.consumptionFilter = filter
      this.$refs.formBuilder.$refs.form.$refs.consumption.$refs.consumption.reset()
    },
    handlePrintSettingsOpen () {
      this.$refs.printerSettingsModal.open()
    },
    handlePrinterSettingsClose () {
      this.isFocused = false
    },
    dimensionsValidation (val) {
      return val > 0 || val === null || 'Minimum value is 0.1'
    },
    handlePrint () {
      this.setInterceptAutoClose(true)
      this.printLabel({ mode: 'print' })
    },
    setDimension (value, type) {
      const actions = {
        width: this.setWidth,
        height: this.setHeight,
        length: this.setLength,
        weight: this.setWeight
      }

      actions[type](value)
    },
    onFocus () {
      this.isFocused = true
    },
    onFocusOut () {
      this.isFocused = false
    },
    handleOpenCloseSettingsModal (isOutside) {
      if (isOutside && typeof isOutside === 'boolean') {
        this.settingsModal = false
      } else {
        this.settingsModal = !this.settingsModal
      }

      this.isFocused = this.settingsModal
    },
    handleDimensionsSubmit (e) {
      e.preventDefault()

      if (!this.deliveryRequest) {
        return
      }

      if (this.isNotValidDimensionsPlace()) {
        this.addWarningNotification('You must set all dimensions!')
        return
      }

      if (this.isMultiPlaceGood) {
        return this.placeSubmit()
      }

      if (Number(this.settings.maxWeight) < this.weight && this.settings.weightControl) {
        return this.$refs.confirmModal.show({ description: 'Weight is over maximum. Are you sure that you want to measure it?' })
            .then(isOk => {
              if (isOk) {
                if (this.isMultiPlaceGood) {
                  return this.placeSubmit()
                } else {
                  this.execute()
                }
              }
            })
      } else {
        if (this.isMultiPlaceGood) {
          return this.placeSubmit()
        } else {
          this.execute()
        }
      }
    },
    // Validate sizes depending on weight control
    isNotValidDimensions () {
      return !this.width || this.width < 0 ||
          !this.height || this.height < 0 ||
          !this.length || this.length < 0 ||
          !this.weight || this.weight < 0
    },
    isNotValidDimensionsPlace () {
      return !this.placeSize.weight || this.placeSize < 0 ||
          !this.placeSize.dimensions.x || this.placeSize < 0 ||
          !this.placeSize.dimensions.y || this.placeSize < 0 ||
          !this.placeSize.dimensions.z || this.placeSize < 0
    },
    measuring (barcode) {
      const actions = {
        X: value => {
          this.setWidth(value)
        },
        Y: value => {
          this.setHeight(value)
        },
        Z: value => {
          this.setLength(value)
        },
        DB: value => {
          const setters = {
            0: (v) => {
              this.setWeight(v)
            },
            1: (v) => {
              this.setWidth(v)
            },
            2: (v) => {
              this.setHeight(v)
            },
            3: (v) => {
              this.setLength(v)
            }
          }

          value.split('/').forEach((value, index) => {

            if (value && value > 0) {
              setters[index](value)
            }
          })
        }
      }

      if (actions[barcode.type]) {
        actions[barcode.type](barcode.value)
      } else {
        this.setWeight(barcode.value)
      }
    },
    handlePlaceDimmensions (value, type) {
      return this.handlePlaceMeasure({ type, value })
    },
    arrangePlaces (places) {
      if (this.places) {
        this.places.sort((a, b) => b.priority - a.priority)
      }
    },
    getCurrentPlace () {
      if (this.places) {
        return this.places[0]
      }

      return null
    },
    handlePlaceMeasure (barcode) {
      const actions = {
        X: value => {
          this.placeSize.dimensions.x = value
        },
        Y: value => {
          this.placeSize.dimensions.y = value
        },
        Z: value => {
          this.placeSize.dimensions.z = value
        },
        DB: value => {
          const setters = {
            0: (v) => {
              this.setWeight(v)
              this.placeSize.weight = v
            },
            1: (v) => {
              this.setWidth(v)
              this.placeSize.dimensions.x = v
            },
            2: (v) => {
              this.setHeight(v)
              this.placeSize.dimensions.y = v
            },
            3: (v) => {
              this.setLength(v)
              this.placeSize.dimensions.z = v
            }
          }

          value.split('/').forEach((value, index) => {
            if (value && value > 0) {
              setters[index](value)
            }
          })
        }
      }

      if (barcode.type) {
        if (actions[barcode.type.toUpperCase()]) {
          actions[barcode.type.toUpperCase()](barcode.value)
        } else {
          this.addErrorNotification('Barcode type is not recognized')
        }
      } else {
        this.placeSize.weight = barcode.value
      }

      if (!this.placeSize.id && this.getCurrentPlace()) {
        let currentPlace = this.getCurrentPlace()

        this.placeSize.id = currentPlace.id
      }

      if (barcode.raw) {
        this.placeSubmit()
      }
    },
    placeSubmit () {
      if (
          this.placeSize.id &&
          this.placeSize.weight &&
          this.placeSize.weight > 0 &&
          this.placeSize.dimensions.x &&
          this.placeSize.dimensions.x > 0 &&
          this.placeSize.dimensions.y &&
          this.placeSize.dimensions.y > 0 &&
          this.placeSize.dimensions.z &&
          this.placeSize.dimensions.z > 0
      ) {
        return this.$service.deliveryServicePlace.save({ ...this.placeSize, id: undefined }, this.placeSize.id)
            .then(() => {
              // this.placeSizes = []
              const index = this.places.findIndex(place => {
                return place.id === this.placeSize.id
              })

              if (index > -1) {
                let places = this.places
                places[index] = { ...places[index], ...this.placeSize }

                let deliveryRequest = this.deliveryRequest
                deliveryRequest.places = places
                this.setDeliveryRequest(deliveryRequest)
                this.setPlaces(places)
                this.handlePlaceChange(places)
              }

              return this.execute().then(() => {
                this.placeSize = createPlaceSize()
              })
            })
            .finally(() => {
              this.$service.printer.clearBlob()
            })
      }
    },
    calculateOverallSizes (places) {
      places = Object.values(places)
      if (!places.length) {
        return { weight: 0, dimensions: { x: 0, y: 0, z: 0 } }
      }

      return places.reduce((acc, place) => {
        acc.weight += Number(place.weight)

        const x = Number(place.dimensions?.x)
        if (x > acc.dimensions?.x) {
          acc.dimensions.x = x
        }

        const y = Number(place.dimensions?.y)
        if (y > acc.dimensions?.y) {
          acc.dimensions.y = y
        }

        const z = Number(place.dimensions?.z)
        if (z > acc.dimensions?.z) {
          acc.dimensions.z = z
        }

        return acc
      }, { weight: 0, dimensions: { x: 0, y: 0, z: 0 } })
    },
    loadBarcode (barcode) {
      this.isLoading = true
      return this.loadDeliveryRequestByBarcode(barcode, true)
          .then(() => {
            if (parseInt(this.$route.params.deliveryRequest) !== parseInt(this.deliveryRequest.id)) {
              this.$router.push(`/workstation/labelling/regular/${this.queue.id}/${this.deliveryRequest.id}`)
            }

            if (this.deliveryRequest) {
              this.$channels.user.publish('openDeliveryRequest', this.deliveryRequest.id)
            }

            // This should be disabled because on change of the delivery request we attempt to reload places
            if (0 && !Array.isArray(this.deliveryRequest.places)) {
              const query = {
                filter: [
                  { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest.id }
                ],
                'order-by': [
                  { type: 'field', field: 'priority', direction: 'asc' }
                ],
                'per_page': 250
              }

              return this.$service.deliveryServicePlace.getAll(query)
                  .then(({ items }) => {
                    this.handlePlaceChange(items)
                    this.isLoad = false
                    this.isMeasured = false
                  })
            }

            this.isLoad = false
            this.isMeasured = false

            if (!this.task || this.taskCreateUnlockStates.includes(this.task?.state) || this.task.state === 'closed') {
              this.isLabelGenerated = false
            }
          })
          .catch(() => {
            this.isLoad = false
            this.isLoading = false
          }).finally(() => {
            this.isLoad = false
            this.isLoading = false
          })
    },
    handlePrintOrderLabel () {
      this.$service.order.get(this.deliveryRequest._embedded.order.id)
          .then(e => {
            const shop = tr(e?._embedded?.shop?.name)
                + '\n' + e?.shipmentDate
                + '\n' + this.deliveryRequest?._embedded?.deliveryService?.name

            this.$service.printer.generateBarcode({
              barcode: `P/O/${e.id}*`,
              label: `${shop}`,
              size: '88x44',
              fontSize: '13'
            })
          })
    },
    handleBarcode (barcode) {
      this.scanInput = barcode

      if (barcode.type === 'skip') {
        this.setWidth(null)
        this.setHeight(null)
        this.setLength(null)
        this.setWeight(null)

        this.isLoad = true

        if (this.deliveryRequest) {
          this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
        }

        return this.loadBarcode(barcode)
      }

      if (barcode.type === 'S/S' && this.deliveryRequest) {
        const query = {
          page: 1,
          filter: [
            { type: 'eq', field: 'barcode', value: barcode.value }
          ]
        }
        this.isLoad = true
        return this.$service.supply.getAll(query)
            .then(items => {
              if (items.items.length === 0) {
                this.addErrorNotification(`No supply with barcode ${barcode.raw} found`)
              }
              let supply = items.items[0]

              if (supply.type === 'package') {
                let currentPlace = null
                if (this.deliveryRequest.places && this.deliveryRequest.places.length === 1) {
                  currentPlace = _.cloneDeep(this.deliveryRequest.places[0])
                }

                const data = {
                  deliveryRequest: this.deliveryRequest.id,
                  dimensions: supply.dimensions,
                  volume: null,
                  weight: null,
                  eav: { ['storage-supply-id']: supply.id }
                }

                return this.$service.deliveryServicePlace.save(data)
                    .then(place => {
                      const places = [...this.deliveryRequest.places, place]
                      this.handlePlaceChange(places)
                      this.isLoad = false
                    })
                    .then(() => {
                      if (currentPlace) {
                        this.$service.deliveryServicePlace.save({ eav: { ['delivery-services-request-place-is-pool']: true } }, currentPlace.id)
                      }
                    })
              } else {
                const result = {
                  count: 1,
                  deliveryRequest: this.deliveryRequest.id,
                  name: supply.name,
                  price: supply.price,
                  supply: supply.id
                }
                return this.$service.deliveryServicesRequestService.save({
                  ...result,
                  deliveryRequest: this.deliveryRequest.id
                })
                    .then(item => {
                      const services = [item, ...this.services]
                      this.services = services
                      this.isLoad = false
                    })
              }
            })
      }

      if (this.scanType === 'delivery-request' || barcode.type === 'P/O' || barcode.type === 'DS/DR') {
        if (this.lockedDeliveryRequests.includes(Number(barcode.value))) {
          this.addErrorNotification('Shipping request is already loaded from someone!')
          return
        }

        if (!this.isMeasured) {
          return this.$refs.confirmModal.show({ description: 'Skip this shipment?' })
              .then(isOk => {
                if (isOk) {
                  this.isMeasured = true
                  this.setDeliveryRequest(null)
                  this.handleBarcode(barcode)
                }
              })
        }

        // When we load next request we must reset sizes
        this.setWidth(null)
        this.setHeight(null)
        this.setLength(null)
        this.setWeight(null)

        this.isLoad = true

        if (this.deliveryRequest) {
          this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
        }

        return this.loadBarcode(barcode)
      }

      if (this.isMultiPlaceGood) {
        return this.handlePlaceMeasure(barcode)
      }

      this.measuring(barcode)

      if (!this.isNotValidDimensions()) {
        if (Number(this.settings.maxWeight) < this.weight && this.settings.weightControl) {
          return this.$refs.confirmModal.show({ description: 'Weight is over maximum. Are you sure that you want to measure it?' })
              .then(isOk => {
                if (isOk) {
                  this.execute()
                }
              })
        } else {
          this.execute()
        }
      }
    },
    useDefaultSettings () {
      const sizes = this.calculateOverallSizes(this.places)
      if (!sizes.weight) {
        this.setWeight(this.settings.default.weight)
      } else {
        this.setWeight(sizes.weight)
      }

      if (!sizes.dimensions.x) {
        this.setWidth(this.settings.default.width)
      } else {
        this.setWidth(sizes.dimensions.x)
      }

      if (!sizes.dimensions.y) {
        this.setHeight(this.settings.default.height)
      } else {
        this.setHeight(sizes.dimensions.y)
      }

      if (!sizes.dimensions.z) {
        this.setLength(this.settings.default.length)
      } else {
        this.setLength(sizes.dimensions.z)
      }

      if (Object.keys(this.placeSizes).length > 0 && Object.keys(this.placeSizes).length !== this.deliveryRequest.places.length) {
        console.log(this.placeSizes)
      } else {
        // this.placeSizes = []

        return this.execute()
      }
    },
    handleSend () {
      if (this.places) {
        const sizes = this.calculateOverallSizes(this.places)
        if (this.queue?.settings && !this.queue?.settings['set-predefined-measurements']) {
          if (!sizes.dimensions.z || !sizes.dimensions.x || !sizes.dimensions.y || !sizes.weight) {
            this.noSizesCheck = true
            return
          }
        }
      }

      if (this.deliveryRequest && this.queue?.settings && this.queue?.settings && this.queue?.settings['trust-incoming-measurements']) {
        if (this.deliveryRequest.weight) {
          this.handlePlaceMeasure({ type: '', value: this.deliveryRequest.weight, raw: this.deliveryRequest.weight })
          this.setWeight(this.deliveryRequest.weight)
        }

        if (this.deliveryRequest.dimensions.x) {
          this.handlePlaceMeasure({
            type: 'x',
            value: this.deliveryRequest.dimensions.x,
            raw: this.deliveryRequest.dimensions.x
          })
          this.setWidth(this.deliveryRequest.dimensions.x)
        }
        if (this.deliveryRequest.dimensions.y) {
          this.handlePlaceMeasure({
            type: 'y',
            value: this.deliveryRequest.dimensions.y,
            raw: this.deliveryRequest.dimensions.y
          })
          this.setHeight(this.deliveryRequest.dimensions.y)
        }
        if (this.deliveryRequest.dimensions.z) {
          this.handlePlaceMeasure({
            type: 'z',
            value: this.deliveryRequest.dimensions.z,
            raw: this.deliveryRequest.dimensions.z
          })
          this.setLength(this.deliveryRequest.dimensions.z)
        }
      } else if (this.places) {
        const sizes = this.calculateOverallSizes(this.places)

        if (!sizes.weight) {
          if (this.settings.setPredefinedMeasurements) {
            this.setWeight(this.settings.default.weight)
          }
        } else {
          this.setWeight(sizes.weight)
        }

        if (!sizes.dimensions.x) {
          if (this.settings.setPredefinedMeasurements) {
            this.setWidth(this.settings.default.width)
          }
        } else {
          this.setWidth(sizes.dimensions.x)
        }

        if (!sizes.dimensions.y) {
          if (this.settings.setPredefinedMeasurements) {
            this.setHeight(this.settings.default.height)
          }
        } else {
          this.setHeight(sizes.dimensions.y)
        }

        if (!sizes.dimensions.z) {
          if (this.settings.setPredefinedMeasurements) {
            this.setLength(this.settings.default.length)
          }
        } else {
          this.setLength(sizes.dimensions.z)
        }
      }
      // this.setWeight(sizes.weight)
      // this.setWidth(sizes.dimensions.x)
      // this.setHeight(sizes.dimensions.y)
      // this.setLength(sizes.dimensions.z)

      if (Object.keys(this.placeSizes).length > 0 && Object.keys(this.placeSizes).length !== this.deliveryRequest.places.length) {
        console.log(this.placeSizes)
      } else {
        // this.placeSizes = []

        return this.execute()
      }
    },
    handlePlaceChange (places, totalBoxes, boxPage, totalBoxPages) {
      if (this.deliveryRequest) {
        this.updateDeliveryRequest({ places })
        if (places.length > 0) {
          this.isMultiPlaceGood = true
        } else {
          this.isMultiPlaceGood = false
        }

        if (this.isBeta) {
          if (places) {
            places = places.filter(e => !e.eav['delivery-services-request-place-is-pool'])
          }

          this.placesToUse = _.cloneDeep(places)
          if (this.isMultiPlaceGood) {
            this.placeSize = createPlaceSize(this.placesToUse.shift())
            this.placeSizes = []
          }
        } else {
          let places2 = _.cloneDeep(places)
          this.setPlaces(Object.values(places2))

          if (this.isMultiPlaceGood) {
            this.placeSize = createPlaceSize(this.places.filter(e => !e.eav['delivery-services-request-place-is-pool']).sort((a, b) => {
              return a.priority - b.priority
            }).shift())
            this.placeSizes = []
          }
        }
      }
    },
    execute () {
      return Promise.resolve(this.isFirstAction && !this.settings.consumption)
          .then(showModal => {
            if (showModal) {
              this.onFocus()

              if (this.appOptions.labellingSettings?.notification?.shippingManifest) {
                const description = this.$t(this.appOptions.labellingSettings.notification.shippingManifest)

                return this.$refs.confirmModal.show({ description })
              }

              return true
            }

            return true
          }).then(isOk => {
            if (this.appOptions.labellingSettings?.notification?.labelConfirm) {
              const description = this.$t(this.appOptions.labellingSettings.notification.labelConfirm)

              return this.$refs.confirmModal.show({ description })
            }

            return true
          })
          .then(isOk => {
            this.onFocusOut()

            if (isOk) {
              let place = null
              if (this.places?.length >= 1) {
                place = this.places[0]
              }

              if (place?.weight) {
                this.setWeight(place.weight)
              }

              if (place?.dimensions?.x) {
                this.setWidth(place.dimensions?.x)
                this.setHeight(place.dimensions?.y)
                this.setLength(place.dimensions?.z)
              }

              this.executeForm()
                  .then((result) => {
                    this.isMeasured = true

                    setTimeout(() => {
                      this.$emit('finish-executing')
                    }, 100)
                  }).catch((err) => {
                this.validStatusCodes.push(400)
                if (this.validStatusCodes.includes(err.status)) {
                  this.isLabelGenerated = true
                } else {
                  this.isLabelGenerated = false
                }

                if (this.deliveryRequest?.id) {
                  return this.loadBarcode({
                    value: this.deliveryRequest?.id
                  })
                }
              })
            }

            if (isOk && this.isFirstAction) {
              this.isFirstAction = false
            }
          })
          .catch(() => {
            this.isMeasured = true
          })
          .finally(() => {
            if (this.noSizesCheck) {
              this.noSizesCheck = false
            }
          })
    },
    createQuery (params) {
      return {
        per_page: 25,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          {
            type: 'in',
            field: 'state',
            values: ['new', 'checking']
          }
        ],
        ...params
      }
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        consumptions: this.loadConsumptions
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery({ search, page })

      return loaders[loader](query)
    },
    handleChange (type) {
      const types = {
        consumptions: {
          getter: 'consumptions',
          page: 'consumptionsPage',
          loadedValue: 'loadedConsumptions'
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }
    },
    getPlaceSizesCount () {
      let count = 0
      if (this.placeSizes) {
        count = Object.keys(this.placeSizes).length
      }

      if (count < this.places?.length) {
        return (Object.keys(this.placeSizes).length + 1)
      } else {
        return this.places?.count
      }
    },
    openDR (dr) {
      window.open(`/#/outbound/shipments/entity/${dr}`, '_blank')
    },
    openOrder (order) {
      this.$service.order.get(order.id)
          .then(e => {
            window.open(`/#/outbound/orders/entity/${e.type}/${e.id}`, '_blank')
          })
    },
    handleCatchAll (value) {
      this.catchAll = value
    },
    doCopy (content) {
      return doCopy(content)
    },
    handleAddSupply (deliveryRequest) {
      const defaultData = {
        state: 'active',
        deliveryRequest: deliveryRequest.id,
      }

      this.$refs.deliveryRequestModal.open(deliveryRequest.id, defaultData)
      this.catchAll = false
    },
    closeAreYouSure () {
      this.areYouSure = false
    },
    openAreYouSure () {
      this.areYouSure = true
    },
    handleEditTask (value, nextStep) {
      this.$service.deliveryServicePreprocessingTask.save({ state: value }, this.task.id)
          .then(() => {
            this.step = nextStep
            if (value === 'closed') {
              this.doneStep = true
            }
          })
    },
    handleCancelTask () {
      this.$service.deliveryServicePreprocessingTask.save({ state: 'cancel' }, this.task.id)
      this.areYouSure = false
    },
    saveSupplies (service) {
      const finalService = { name: service.name, price: service.price, supply: service.id, count: service.quantity }
      return this.$service.deliveryServicesRequestService.save({
        ...finalService,
        deliveryRequest: this.deliveryRequest.id
      })
          .then(item => {
            const test = [item, ...this.services]
            this.handleChangeServices(test)
          })
    },
    saveOrderSupplies (service) {
      if (this.deliveryRequest?._embedded?.order.id) {
        const finalService = { name: service.name, price: service.price, supply: service.id, count: service.quantity }
        return this.$service.productsService.save({ ...finalService, order: this.deliveryRequest._embedded.order.id })
            .then(item => {
              const test = [item, ...this.servicesOrder]
              this.handleChangeServicesOrder(test)
            })
      }
    },
    handleChangeServices (services) {
      this.services = services
    },
    handleChangeServicesOrder (services) {
      this.servicesOrder = services
    },
    checkLogo (dr) {
      if (dr && dr._embedded && dr._embedded.deliveryService) {
        if ((dr || {})._embedded.deliveryService.logo.includes('http')) {
          return (dr || {})._embedded.deliveryService.logo
        } else {
          return this.appOptions.defaultServer + dr._embedded.deliveryService.logo
        }
      }
    },
    handleItemSubmit (item, place, oldPlace, copyMade = false) {
      delete item.quantity
      let updatedPlace
      this.placesToUse = this.placesToUse.map(p => {
        if (p.id === place.id) {
          let hasItem = false

          p.items = p.items.map(i => {
            if (i.id === item.id) {
              hasItem = true
              return item
            }

            return i
          })

          if (!hasItem) {
            p.items.push(item)
          }
          if (copyMade && hasItem) {
            p.items.push(item)
          }
          updatedPlace = p
        }

        return p
      })
      if (updatedPlace) {
        this.placesToUse = this.placesToUse.map(e => {
          if (e.id === oldPlace.id && !copyMade) {
            e.items = e.items.filter(i => i.id !== item.id)
            this.$refs.drProduct.close()
            this.handleCloseItemModal()
            return e
          }
          this.$refs.drProduct.close()
          this.handleCloseItemModal()
          return e
        })
      }
      this.$refs.drProduct.close()
      this.handleCloseItemModal()
    },
    handleCloseItemModal () {
      this.$refs.labellingMovement.close()
    },
    handleReset () {
      this.setDeliveryRequest(null)
    },
    handleSubmitWaiting (data) {
      return this.$service.storageItemEntity.post(data)
    },
    handleSendWaiting () {
      if (this.deliveryRequest._embedded.order) {
        return this.$refs.createItemModal.open(this.deliveryRequest._embedded.order)
      }

      this.$refs.createItemModal.open()
    }
  }
}
</script>
