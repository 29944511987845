<template>
  <div class="row" style="align-items: center">
    <q-list
        class="col rounded"
    >
      <q-item
          v-for="(place) in currentPlacesFiltered"
          :key="place.id"
          class="block text-center q-item-no-border full-width q-item-no-padding"
          dense
      >
        <q-card class="col no-border" flat borderless>
          <q-card-section class="row no-wrap">
            <div class="col align-items-center text-left">
              <q-chip class="q-my-none text-center q-px-lg" size="lg" square :color="colorOfBox(place)">
                {{ place.priority }}
              </q-chip>
            </div>

            <div class="col-2">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    label="Weight"
                    :value="place.weight || null"
                    :input-label="`Weight (${appOptions.defaultDimensions.weightUnit})`"
                    :update-function="newValue => updatePlaceDimensions('weight', newValue, place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>
              </q-btn-group>
            </div>

            <div class="col-4">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    label="W"
                    :value="place?.dimensions?.x ? place.dimensions.x : null"
                    :input-label="`W (${appOptions.defaultDimensions.dimensionUnit})`"
                    :update-function="newValue => updatePlaceDimensions('x', newValue, place)"
                    :reset-change="checkResetDimensions(place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>

                <editable-input-field
                    label="H"
                    :value="place?.dimensions?.y ? place.dimensions.y : null"
                    :input-label="`H (${appOptions.defaultDimensions.dimensionUnit})`"
                    :update-function="newValue => updatePlaceDimensions('y', newValue, place)"
                    :reset-change="checkResetDimensions(place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>

                <editable-input-field
                    label="L"
                    :value="place?.dimensions?.z ? place.dimensions.z : null"
                    :input-label="`L (${appOptions.defaultDimensions.dimensionUnit})`"
                    :update-function="newValue => updatePlaceDimensions('z', newValue, place)"
                    :reset-change="checkResetDimensions(place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>
              </q-btn-group>
            </div>

            <div class="col-3 text-right">
              <q-btn-group>
                <q-chip class="q-my-none text-center q-px-lg" size="lg"
                        square
                        clickable
                        @click="handleOpen(place)"
                        :icon-right="iconName(place)"
                        :disable="!place || place.items < 0"
                >
                  {{
                    place.items.reduce((sum, item) => Number(sum) + Number(item.count), 0)
                  }}/{{ place.items.length || 0 }}

                  <q-tooltip>
                    {{ 'Quantity in box. Click to expand' }}
                  </q-tooltip>
                </q-chip>

                <q-btn-dropdown
                    size="md"
                    flat
                    round
                    dense
                    no-icon
                    dropdown-icon="more_horiz"
                >
                  <q-list>
                    <q-item clickable v-close-popup>
                      <q-item-section @click="handleSetCurrent(place)">
                        <q-item-label>
                          {{ 'Set as current' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup>
                      <q-item-section @click="handleGetLabel(place)">
                        <q-item-label>
                          {{ 'Get box label' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="selectedItems.length === 0 || !(places.length > 1)">
                      <q-item-section @click="handleMove(selectedItems, place)">
                        <q-item-label>
                          {{ 'Move item' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup
                            :disable="selectedItems.length === 0 || !(places.length > 1) || !hasInstructions(selectedItems)">
                      <q-item-section @click="handleInstructions(selectedItems, place)">
                        <q-item-label>
                          {{ 'Instructions' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="!(places.length > 1)">
                      <q-item-section @click="handleCopyBox(place)">
                        <q-item-label>
                          {{ 'Clone Box' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item v-if="!poolEnabled" clickable v-close-popup :disable="selectedItems.length === 0">
                      <q-item-section @click="handleMarkAsNotShipped(selectedItems)">
                        <q-item-label>
                          {{ 'Mark as not shipped' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="!(places.length > 1)">
                      <q-item-section @click="handleRemovePlace(place)">
                        <q-item-label>
                          {{ 'Delete Box' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <q-item-label>
                          {{ `Box ID: ${place.id}` }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </q-btn-group>
            </div>
          </q-card-section>
          <q-card-section v-if="ifOpened(place)">
            <q-table
                class="sticky-header-table"
                row-key="id"
                :rows-per-page-label="$t('Rows per page')"
                :rows="place.items"
                :columns="columns"
                selection="multiple"
                v-model:pagination="pagination"
                hide-bottom
                v-model:selected="selectedItems"
                virtual-scroll
                binary-state-sort
                flat
            >

              <template v-slot:header-selection="props">
                <q-checkbox
                    size="md"
                    :model-value="selectedItems.length > 0"
                    color="amber-6"
                    @click="handleSelectAll(props)"
                />
              </template>

              <template v-slot:body="props">
                <q-tr
                    :props="props"
                    class="clickable"
                    :class="(props.row.state === 'return') ? 'bg-negative text-white' : ''"
                >
                  <td class="q-td text-left" style="width: 5%">
                    <q-checkbox
                        size="lg"
                        :model-value="!!selectedItems.find(({id}) => id === props.row.id)"
                        color="amber-6"
                        @click="handleSelectItems(props.row)"
                    />
                  </td>

                  <q-td
                      key="number"
                      :props="props"
                      style="width: 5%"
                  >
                    {{ props.rowIndex + 1 }}
                  </q-td>

                  <q-td
                      key="image"
                      :props="props"
                      style="width: 5%"
                  >
                    <div @click="showImageZoomDialog(props.row.image || fallbackImage)">
                      <img
                          :src="props.row.image || fallbackImage"
                          style="width: 60px; height: 60px; object-fit: contain;"
                          @error="onImageLoadFailure"
                      >
                    </div>

                    <q-dialog v-model="imageZoomDialog" persistent>
                      <div @click="closeImageZoomDialog" style="object-fit: contain;">
                        <img :src="zoomedSrc">
                      </div>
                    </q-dialog>
                  </q-td>

                  <q-td
                      key="name"
                      :props="props"
                      style="width: 75%; white-space: pre-wrap"
                      v-on:click="handleMove([props.row], place)"
                  >
                    <span class="row" v-html="highlightSubstring(props.row.name , props.row)"></span>
                  </q-td>

                  <q-td
                      key="quantity"
                      :props="props"
                      style="width: 15%;min-width: 30px"
                  >
                    <editable-input-field
                        label="Qty"
                        :value="props.row.count"
                        :update-function="newValue => handleQuantityUpdate(newValue, props.row)"
                        :disabled="disabled"
                        @change-catch-all="handleCloseEditItem"
                        :rules="[
                          (val) => /^[0-9]\d*$/.test(val)
                        ]"
                        :disable="isLoading"
                        style="min-width: 65px;"
                    ></editable-input-field>
                  </q-td>
                </q-tr>
                <q-tr
                    v-if="props.row.state === 'return'"
                    :props="props"
                    class="clickable"
                    :class="(props.row.state === 'return') ? 'bg-negative text-white' : ''"
                >
                  <q-td
                      key="number"
                      :props="props"
                      auto-width
                      colspan="5"
                  >
                    <editable-textarea-input
                        :value="props.row.comment"
                        :label="$t('Comment')"
                        class="text-white bg-white"
                        :outlined="true"
                        :input-label="$t('Comment')"
                        @change-catch-all="handleCloseEditItem"
                        :update-function="newValue => handleCommentUpdate(newValue, props.row)"
                    ></editable-textarea-input>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>
        </q-card>
      </q-item>

    </q-list>
  </div>
  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
          {{ $t('Are You Sure?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="negative"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Delete product')"
              @click="handleRemovePlace()"
          />

          <q-btn
              textarea="center"
              :label="$t('Cancel')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>

  </q-dialog>

  <confirm-modal ref="confirmModal"/>

  <d-r-product
      ref="drProduct"
      :delivery="deliveryRequest"
      @change-catch-all="handleCloseEditItem"
      @submit="handleItemSubmit"
  />

  <clone-boxes-modal ref="cloneBoxes" @quantity="handleClonePlace"/>
</template>

<script>


import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmModal from '@/apps/app/components/confirm-modal/ConfirmModal.vue'
import DRProduct from '@/apps/app/components/products/DRProduct.vue'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'
import CloneBoxesModal from '@/apps/app/components/modals/CloneBoxesModal.vue'
import EditableTextareaInput from '@/apps/app/components/delivery-services/EditableTextareaInput.vue'

export default {
  name: 'BoxesPool',
  components: {
    EditableTextareaInput,
    CloneBoxesModal,
    EditableInputField,
    DRProduct,
    ConfirmModal
  },
  emit: ['change-catch-all', 'change', 'request-move-items', 'request-item-instructions', 'delete', 'clone-place', 'mark-shipped', 'moved', 'update-place'],
  props: {
    scanInput: {
      type: Object,
      default () {
        return null
      }
    },
    places: {
      type: Array,
      default () {
        return []
      }
    },
    poolEnabled: {
      type: Object,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    place: {
      type: Object,
      default () {
        return {}
      }
    },
    deliveryRequest: {
      type: [String, Number],
      default () {
        return null
      }
    },
    allPlaces: {
      type: Array,
      default () {
        return []
      }
    },
    itemToMove: {
      type: Object,
      default () {
        return null
      }
    },
  },
  mounted () {
    this.arrangePlaces(this.places)
  },
  watch: {
    scanInput (barcode) {
      if (!barcode.type && this.places && this.places.length >= 1) {
        let place = this.places[0]
        if (this.poolPlace && this.poolPlace.items) {
          place = this.poolPlace
        }

        const string = `(${barcode.value})`
        const found = place.items.filter(e => e.name.includes(string))
        if (found.length > 0) {
          const itemToMove = found[0]

          this.selectedItems.push(itemToMove)

          return
        }
      }
    },
    places: {
      handler (value) {
        this.arrangePlaces(this.places)
      },
      deep: true
    },
    poolEnabled: {
      handler (value) {
        if (!value) {
          this.openedBoxes.push(this.currentPlaces[0])
        }
      },
    }
  },
  data () {
    return {
      items: [],
      currentPlaces: null,
      dimensions: {},
      openedBoxes: [],
      finalWeight: null,
      finalDimensions: null,
      height: null,
      width: null,
      length: null,
      weight: null,
      areYouSure: false,
      fallbackImage: './assets/img/fallback-image/package.png',
      selectedItems: [],
      placeUpdates: [],
      imageZoomDialog: false,
      zoomedSrc: '',
      pagination: {
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('QTY'),
          name: 'quantity',
          align: 'center'
        }
      ],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    highlightSubstring () {
      return (text, item) => {
        if (!this.scanInput) {
          return text
        }

        if (this.scanInput.type === '') {
          const value = `(${this.scanInput.value})`
          const regex = new RegExp(value, 'gi')
          if (this.itemToMove && this.itemToMove.id === item.id) {
            return text.replace(regex, (match) => {
              return `<span class="bg-positive">${match}</span>`

            })
          } else if (this.itemToMove) {
            return text.replace(regex, (match) => {
              return `<span class="bg-yellow">${match}</span>`
            })
          } else {
            return text
          }
        } else {
          return text
        }
      }
    },
    currentPlacesFiltered () {
      if (!this.currentPlaces) {
        return []
      }

      return this.currentPlaces.filter(place => !place.eav['delivery-services-request-place-is-pool'] && !place._hidden && place.type !== 'pallet')
    }
  },
  methods: {
    ...mapActions([
      'printBoxLabel',
    ]),
    ...mapMutations([
      'setInterceptAutoClose',
    ]),
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    arrangePlaces (places) {
      this.currentPlaces = places

      if (this.currentPlaces?.length > 0) {
        this.currentPlaces.sort((a, b) => b.priority - a.priority)

        this.openedBoxes.push(this.currentPlaces[0])
      }
    },
    showImageZoomDialog (src) {
      this.zoomedSrc = src
      this.imageZoomDialog = true
    },
    closeImageZoomDialog () {
      this.zoomedSrc = ''
      this.imageZoomDialog = false
    },
    iconName (place) {
      if (this.openedBoxes.filter(e => e.id === place.id).length > 0) {
        return 'expand_less'
      } else {
        return 'expand_more'
      }
    },
    handleSelectItems (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true

          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems.push(item)
      }
    },
    colorOfBox (place) {
      if (place.state === 'filling') {
        return 'red-6'
      }

      if (place.weight && place.dimensions && place.dimensions.x && place.dimensions.y && place.dimensions.z) {
        return 'green-6'
      }

      return 'orange-6'
    },
    handleItemSubmit (item, place, oldPlace, copyMade = false) {
      let updatedPlace
      this.currentPlaces = this.currentPlaces.map(p => {
        if (p.id === place.id) {
          let hasItem = false

          p.items = p.items.map(i => {
            if (i.id === item.id) {
              hasItem = true
              return item
            }

            return i
          })

          if (!hasItem) {
            p.items.push(item)
          }
          if (copyMade && hasItem) {
            p.items.push(item)
          }
          updatedPlace = p
        }

        return p
      })

      if (updatedPlace) {
        this.currentPlaces = this.currentPlaces.map(e => {
          if (e.id === oldPlace.id && !copyMade) {
            e.items = e.items.filter(i => i.id !== item.id)
            this.handleCloseItemModal()
            return e
          }
          this.handleCloseItemModal()
          return e
        })
        this.$emit('change', this.currentPlaces)
      }
      this.handleCloseItemModal()
    },
    handleCloseItemModal () {
      this.$refs.labellingMovement.close()
    },
    handleCloseEditItem (value) {
      this.$eventBus.update('catchAll', {
        'catchAll': value
      })

      this.$emit('change-catch-all', value)
    },
    checkResetDimensions (place) {
      return this.placeUpdates[place.id] && this.placeUpdates[place.id].reset
    },
    updatePlaceDimensions (state, value, place) {
      if (Number(value) <= 0) {
        return
      }

      if (state === 'weight') {
        place.weight = value
        return this.$service.deliveryServicePlace.save({
          weight: value
        }, place.id).then((updatedPlace) => {
          const index = this.places.findIndex(place => {
            return place.id === updatedPlace.id
          })

          if (index !== -1) {
            // If the item exists in the array, replace it with the updated version
            this.places[index] = updatedPlace
          }
          this.finalWeight = value
          this.checkIfChangeReady()
        })
      } else {
        if (!place.dimensions) {
          place.dimensions = {}
        }

        if (!this.placeUpdates[place.id] || this.placeUpdates[place.id].reset) {
          if (place.dimensions) {
            this.placeUpdates[place.id] = {
              dimensions: place.dimensions
            }
          } else {
            this.placeUpdates[place.id] = {
              dimensions: {}
            }
          }
        }

        this.placeUpdates[place.id].dimensions[state] = value

        // Check if all dimensions (x, y, z) are defined and then save them
        if (this.placeUpdates[place.id].dimensions.x !== undefined && this.placeUpdates[place.id].dimensions.y !== undefined && this.placeUpdates[place.id].dimensions.z !== undefined) {
          return this.$service.deliveryServicePlace.save(this.placeUpdates[place.id], place.id)
              .then((updatedPlace) => {
                const index = this.places.findIndex(place => {
                  return place.id === updatedPlace.id
                })

                if (index !== -1) {
                  // If the item exists in the array, replace it with the updated version
                  this.places[index] = updatedPlace
                }
                this.finalDimensions = place.dimensions
                this.checkIfChangeReady()
                delete this.placeUpdates[place.id].dimensions
                this.placeUpdates[place.id].reset = true
              })
        } else {
          return Promise.reject('The sizes are not complete')
        }
      }
    },
    checkIfChangeReady () {
      let changeDimensions = false
      if (this.finalWeight && this.finalDimensions && this.finalDimensions.x && this.finalDimensions.y && this.finalDimensions.z) {
        changeDimensions = true
      }
      if (changeDimensions) {
        const dimensions = {
          weight: this.finalWeight,
          dimensions: this.finalDimensions
        }
        this.$emit('moved', dimensions)
        changeDimensions = false
        this.finalDimensions = null
        this.finalWeight = null
      }
    },
    handleRemovePlace (place) {
      if (!(this.places.length > 1)) {
        return
      }

      return this.$refs.confirmModal.show({ description: this.$t('The box will be deleted with all products in it. Are you sure?') })
          .then(isOk => {
            if (isOk) {
              this.$service.deliveryServicePlace.save({ state: 'deleted' }, place.id)
                  .then(place => {
                    this.currentPlaces = this.currentPlaces.filter(({ id }) => id !== place.id)
                    if (this.currentPlaces.length === 1 && this.currentPlaces[0].priority === 1) {
                      this.$emit('delete', this.currentPlaces[0], place, true)
                    } else {
                      this.$emit('delete', undefined, place, false)
                    }
                  })
            }
          })
    },
    ifOpened (place) {
      return this.openedBoxes.filter(e => e.id === place.id).length > 0
    },
    handleOpen (place) {
      if (this.openedBoxes.filter(e => e.id === place.id).length > 0) {
        this.openedBoxes = this.openedBoxes.filter(e => e.id !== place.id)
      } else {
        this.openedBoxes.push(place)

      }
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    handleGetLabel (place) {
      this.setInterceptAutoClose(true)
      this.printBoxLabel({ mode: 'print', place: place })
    },
    handleSetCurrent (place) {
      this.$emit('update-place', {
        sourcePlace: place,
        data: {
          state: 'filling'
        },
      })
    },
    handleMove (items = [], place) {
      if (!(this.places.length > 1)) {
        return
      }

      this.selectedItems = []

      this.$emit('request-move-items', {
        sourcePlace: place,
        targetPlace: this.place,
        items: items,
      })
    },
    hasInstructions (selectedItems) {
      let hasInstructions = false
      if (Array.isArray(selectedItems)) {
        selectedItems.forEach((item) => {
          if (item.orderProduct?.eav && item.orderProduct?.eav['storage-assembling-instructions']) {
            hasInstructions = true
          }
        })
      }

      return hasInstructions
    },
    handleInstructions (items = [], place) {
      if (!(this.places.length > 1)) {
        return
      }

      this.selectedItems = []

      this.$emit('request-item-instructions', {
        sourcePlace: place,
        targetPlace: this.place,
        items: items,
      })
    },
    handleCommentUpdate (text, item) {
      return this.$service.deliveryServicePlaceItems.save({ 'comment': text }, item.id).then((updatedItem) => {
        item.comment = text

        return Promise.resolve(updatedItem)
      })
    },
    handleQuantityUpdate (count, item) {
      return this.$service.deliveryServicePlaceItems.save({ 'count': count }, item.id).then((updatedItem) => {
        item.count = count

        return Promise.resolve(updatedItem)
      })
    },
    handleCopyBox (place) {
      this.$refs.cloneBoxes.open(place, this.deliveryRequest, this.places)
    },
    handleClonePlace (event) {
      let place = event.place

      let deliveryRequest = place._embedded.deliveryRequest._links.self.href.split('/').pop()

      let placeData = {
        deliveryRequest: deliveryRequest,
        dimensions: place.dimensions,
        volume: null,
        weight: place.weight,
      }

      this.$emit('clone-place', {
        place: placeData,
        items: place.items,
        quantity: event.quantity
      })
    },
    disableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: false
      })
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    },
    async handleMarkAsNotShipped (items = []) {
      this.$emit('mark-shipped', {
        items: items,
      })
    },
  },
}
</script>

<style scoped>
.q-item-no-padding {
  padding: 0;
}
</style>