<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section v-if="options && options.message">
        <div class="text-h6 text-center">
          {{ options.message }}
        </div>
      </q-card-section>

      <q-card-section v-else>
        <div class="text-h6 text-center">
          {{ $t('Scan barcode') }}
        </div>
      </q-card-section>

      <q-card-section v-if="isOpen">
        <barcode-input
          :settings="{catchAll: false}"
          :has-max-width="false"
          @barcode="handleBarcode"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import BarcodeInput from '../barcode-input/BarcodeInput'

export default {
  name: 'EntityUpdateModal',
  components: {
    BarcodeInput
  },
  props: {
    manual: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isOpen: false,
      options: {},
      data: {},
      values: []
    }
  },
  methods: {
    ...mapMutations([
      'addNotification'
    ]),
    resolve () {},
    reject () {},
    show (data = {}, options = {}) {
      this.data = data
      this.options = options
      this.isOpen = true
      this.scans = []

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    hide () {
      this.isOpen = false
    },
    handleOk () {
      this.resolve(true)
    },
    handleCancel () {
      this.resolve(false)
    },
    handleSkip () {
      this.resolve(true)
    },
    save (url, data) {
      return this.$service.httpClient.post(url, data, 'application/json', 'application/json', '')
        .then(data => {
          this.values = []
          return data
        })
    },
    autoSubmit (data) {
      return this.save(this.options.api, data)
        .then(() => {
          return this.resolve(true)
        })
        .catch(() => {
          return this.resolve(false)
        })
    },
    submit () {
      if (this.values.length <= 0) {
        return Promise.resolve(true)
      }

      return this.save(`${this.options.api}/bulk`, this.values.map(create => ({ create })))
    },
    getValue (barcode) {
      return this.options.base64Encode
        ? window.btoa(barcode.raw)
        : barcode.raw
    },
    handleBarcode (barcode) {
      const data = {
        ...this.data,
        [this.options.requestField]: this.getValue(barcode)
      }

      this.values.push(data)

      if (this.manual) {
        return this.resolve(true)
      }

      return this.autoSubmit()
    }
  }
}
</script>
