<template>
  <div>
    <filter-collapse
        :is-open="isOpenFilter"
        :options="{
        defaultFilter: filter,
        fields: filterFields,
        style: {
          noGroups: true
        }
      }"
        @submit="handleFilterSubmit"
        @close="toggleFilter"
    />

    <div
        v-for="(item, i) in data.objects"
        :key="i"
        class="q-mb-sm"
    >
      <component
          :is="types[item.type]"
          v-bind="{ data: item.data, rawData: item }"
          @click="handleClick"
      />
    </div>

    <div
        class="fixed-bottom-right text-right"
        style="bottom: 10px; right: 20px;"
    >
      <q-btn
          v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
          class="q-mb-sm block q-ml-auto fab"
          fab
          size="lg"
          icon-right="print"
          color="light-blue-9"
          :label="$t('Print label')"
          label-position="left"
          dark
          @click="handleClick({ type: 'storage.queue.task.print' })"
      />


      <q-btn
          v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
          class="q-mb-sm block q-ml-auto fab"
          fab
          size="lg"
          icon-right="add"
          color="light-blue-7"
          :label="$t('Add supplies')"
          label-position="left"
          dark
          @click="handleClick({ type: 'storage.service.opened' })"
      />


      <q-btn
          v-if="dataObjects['request-data']"
          fab
          class="q-mb-sm block q-ml-auto fab"
          size="lg"
          icon-right="archive"
          color="primary"
          :label="$t((dataObjects['request-data'] && dataObjects['request-data'].options && dataObjects['request-data'].options.label) || 'Box')"
          label-position="left"
          dark
          @click="handleOpenRequestData"
      />

      <q-btn
          v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
          fab
          class="q-mb-sm block q-ml-auto fab"
          size="lg"
          icon-right="skip_next"
          color="dark"
          :label="$t('Skip the task')"
          label-position="left"
          dark
          @click="openConfirmation"
      />

      <q-btn
          v-if="dataObjects[$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest]"
          class="q-mb-sm block q-ml-auto fab"
          fab
          size="lg"
          icon-right="inventory"
          color="light-blue-9"
          :label="$t('Shipment')"
          label-position="left"
          dark
          @click="handleOpenShipment(dataObjects[$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest])"
      />

      <q-btn
          v-if="queue"
          class="q-mb-sm block q-ml-auto fab"
          fab
          size="lg"
          icon-right="assignment_late"
          color="negative"
          :label="$t('See skipped tasks')"
          label-position="left"
          dark
          @click="handleOpenTasks"
      />
    </div>

    <q-dialog v-model="amountModal">
      <q-card style="min-width: 50vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6 q-my-none">
            {{ $t('Amount') }}
          </div>

          <q-space/>

          <q-btn
              icon="close"
              flat
              round
              dense
              @click="handleCloseAmount"
          />
        </q-card-section>

        <q-card-section class="row justify-center items-center">
          <q-btn
              color="light-blue-9"
              text-color="white"
              size="sm"
              :label="ignoreMax?$t('Max') + ':' + '-' : $t('Max') + ': ' + maxAmount"
              @click="handleAddAll"
          />

          <div
              class="col row items-center q-px-sm justify-center"
              style="max-width: 350px;"
          >
            <q-btn
                color="negative"
                text-color="white"
                size="sm"
                icon="remove"
                :disable="count <= 1 || isLoading"
                @click="handleDecrease"
            />

            <div
                class="col q-px-sm"
                style="min-width: 80px; max-width: 250px;"
            >
              <q-input
                  standout="bg-teal text-white"
                  type="number"
                  :model-value="count"
                  :label="$t('Amount')"
                  :disable="isLoading"
                  dense
                  @update:model-value="handleAmount"
                  @focus="onFocus"
                  @blur="onFocusOut"
              />
            </div>

            <q-btn
                color="positive"
                text-color="white"
                size="sm"
                icon="add"
                :disable="ignoreMax ? isLoading : isLoading || count >= maxAmount"
                @click="handleIncrease"
            />
          </div>

          <q-btn
              color="light-blue-9"
              text-color="white"
              size="sm"
              :label="$t('Submit')"
              @click="handleItemClick({ data: product })"
          />
        </q-card-section>

        <q-card-section class="q-my-sm" v-if="!ignoreMax">
          <dynamic-products-collection :data="[product]"/>
        </q-card-section>

        <q-card-section class="q-my-sm" v-if="product._embedded?.orderProduct?.productOfferRaw?.type === 'bundle'">
          <dynamic-bundle :data="extractBundle(product)"/>
        </q-card-section>

      </q-card>
    </q-dialog>

    <barcode-input-modal ref="barcodeInputModal"/>

    <confirm-modal ref="confirmModal"/>

    <entity-update-modal ref="entityUpdateModal" :manual="fastMode"/>

    <request-data-modal
        ref="requestDataModal"
        @submit="handleRequestData"
        @close="handleRequestDataClose"
    />

    <assembling-tasks-modal ref="assemblingTasksModal"/>

    <order-product-tasks-modal ref="orderProductTasksModal"/>

    <add-supplies-modal ref="AddSuppliesModal"/>

    <places-modal ref="PlacesModal"/>

    <portal v-if="!!queue" to="assembling-queues">
      <div class="row items-center">
        <q-btn
            color="dark"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="$t('Filter')"
            no-caps
            @click="toggleFilter"
        />

        <q-btn
            color="dark"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="$t('All queues')"
            no-caps
            @click="handleClick({ type: 'storage.queue.choose' })"
        />
      </div>
    </portal>

    <portal v-if="!!queue" to="assembling-buttons">
      <q-btn
          color="dark"
          text-color="white"
          size="sm"
          :icon-right="isOpenQueue ? 'expand_less' : 'expand_more'"
          :label="$t('Packed')"
          no-caps
          @click="handleQueueOpenClose"
      />
    </portal>

    <portal v-if="!!queue" to="assembling-body">
      <q-slide-transition>
        <dynamic-product-tasks-table
            v-if="isOpenQueue"
            :queue="queue"
        />
      </q-slide-transition>
    </portal>

  </div>

  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
          {{ $t('Are you sure you want to skip the task?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="dark"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Skip task')"
              @click="handleConfirm"
          />

          <q-btn
              textarea="center"
              :label="$t('Cancel')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Services
import { InstructionsService } from '../../services/instructions.service'

// Components
import DesktopMessage from './../../components/dynamic-components/desktop-message'
import DynamicCollection from './../../components/dynamic-components/dynamic-collection'
import DynamicOrder from './../../components/dynamic-components/dynamic-order'
import PickingItem from './../../components/picking/picking-item'
import NewPickingPlace from './../../components/picking/new-picking-place'
import PickingQueue from './../../components/picking/picking-queue'
import BarcodeInputModal from '../../components/barcode-input-modal/BarcodeInputModal'
import DynamicTask from '../../components/dynamic-components/dynamic-task'
import DynamicProductsCollection from '../../components/dynamic-components/dynamic-products-collection.vue'
import ConfirmModal from '../../components/confirm-modal/ConfirmModal.vue'
import RequestDataModal from '../../components/modals/RequestDataModal.vue'
import DynamicDeliveryRequest from '../../components/dynamic-components/dynamic-delivery-request.vue'
import DynamicDeliveryRequestPlace from '../../components/dynamic-components/dynamic-delivery-request-place.vue'
import AssemblingTasksModal from '../../components/modals/AssemblingTasksModal.vue'
import DynamicProductTasksTable from '../../components/dynamic-components/dynamic-product-tasks-table.vue'
import EntityUpdateModal from '../../components/modals/EntityUpdateModal.vue'
import MeasuringFastModal from '../../components/modals/MeasuringFastModal.vue'
import OrderProductTasksModal from '../../components/modals/OrderProductTasksModal.vue'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import AddSuppliesModal from '../../components/modals/AddSuppliesModal.vue'
import DynamicBundlesCollection from '@/apps/app/components/dynamic-components/dynamic-bundles-collection.vue'
import DynamicBundle from '@/apps/app/components/dynamic-components/dynamic-bundle.vue'
import PlacesModal from '@/apps/app/components/modals/PlacesModal.vue'

export default {
  name: 'DynamicOrderAssembling',
  emits: ['stop-loading', 'start-loading', 'fast-mode', 'show-barcode-field', 'block', 'unblock'],
  components: {
    PlacesModal,
    DynamicBundlesCollection,
    DynamicBundle,
    PickingQueue,
    PickingItem,
    DesktopMessage,
    NewPickingPlace,
    DynamicCollection,
    BarcodeInputModal,
    DynamicOrder,
    DynamicTask,
    DynamicProductsCollection,
    ConfirmModal,
    RequestDataModal,
    DynamicDeliveryRequest,
    DynamicDeliveryRequestPlace,
    AssemblingTasksModal,
    DynamicProductTasksTable,
    EntityUpdateModal,
    MeasuringFastModal,
    OrderProductTasksModal,
    FilterCollapse,
    AddSuppliesModal
  },
  props: {
    fastMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    barcode: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpenFilter: false,
      filterFields: [
        'id',
        'extId',
        'clientId',
        'profile',
        'phone',
        'shipmentDate.from',
        'shipmentDate.to',
        'created.from',
        'created.to',
        'deliveryService',
        'paymentState',
        'state',
        'shop',
        'source'
      ],
      filter: [],
      isOpenQueue: false,
      isLoading: false,
      types: {
        message: 'desktop-message',
        'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest': 'dynamic-delivery-request',
        'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest\\Place': 'dynamic-delivery-request-place',
        // 'Orderadmin\\Storage\\Entity\\Assemblage\\Task': 'dynamic-task',
        'Orderadmin\\Storage\\Entity\\AbstractPlace': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Row': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Section': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Employee': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Sorting': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Distribution': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Assembly': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Universal': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Pallet': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Defected': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Room': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Item': 'picking-item',
        'Orderadmin\\Storage\\Entity\\Assemblage\\Queue': 'picking-queue',
        collection: 'dynamic-collection',
        'Orderadmin\\Products\\Entity\\AbstractOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\ReturnOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\RetailOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder': 'dynamic-order',
        'Orderadmin\\Storage\\Entity\\Warehouse': 'warehouses-collection',
      },
      amountModal: false,
      amountModalChanged: false,
      maxAmount: 1,
      count: 1,
      queue: null,
      queues: [],
      adapter: null,
      product: null,
      dataObjects: {},
      data: {
        place: this.place?.id,
        objects: [
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          },
          ...(this.queues ?? [])
        ]
      },
      instructionsService: null,
      scannedBarcodes: [],
      orderId: null,
      warehouse: '',
      sourcePlace: '',
      itemToPack: '',
      ignoreMax: false,
      isConfirmed: false,
      offer: '',
      entity: null,
      areYouSure: false
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'settings',
      'assemblingQueues',
      'place'
    ])
  },
  watch: {
    barcode (newVal) {
      if (this.data.event === 'storage.assembling.scan.item' && !this.warehouse) {
        if (newVal.type === 'P/O' || newVal.type === 'S/P') {
          this.data.event = 'storage.order.opened'
          this.data.entityClass = 'Orderadmin\\Products\\Entity\\AbstractOrder'
          this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task] = null
        } else {
          this.handleProductScan(newVal)
          return
        }
      }

      this.handleBarcode(newVal)
    },
    assemblingQueues (newVal) {
      this.queues = newVal

      this.data = {
        place: this.place?.id,
        objects: [
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          },
          ...(this.queues ?? [])
        ]
      }

      if (this.assemblingQueues.length > 0) {
        let foundObject
        if (this.$route.params.queue) {
          foundObject = this.assemblingQueues.find(item => {
            return parseInt(item.data.id) === parseInt(this.$route.params.queue)
          })

          if (foundObject) {
            this.handleQueueClick(foundObject)
          }
        }

        if (!foundObject && this.queues.length === 1) {
          this.handleQueueClick(this.queues[0])
        }
      }
    },
    dataObjects (newVal) {
      if (this.dataObjects.instruction) {
        this.instructionsService.execute(this.dataObjects.instruction.data)
            .then(result => {
              delete this.dataObjects.instruction
            })
      }
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params), this.$router, this.$route)
    // Demo instruction for easy testing
    // this.instructionsService.execute([
    //   {
    //     type: 'label-print',
    //     data: {
    //       size: '58x40',
    //       barcode: 'P/O/53235429*',
    //       label: 'loggsssiiiiiiisssswwwwssssssssssssssssssss'
    //     },
    //     options: {
    //       confirm: 'P/O/532329*'
    //     }
    //   }
    // ])

    if (this.assemblingQueues.length > 0) {
      this.queues = this.assemblingQueues
    }

    document.addEventListener('keydown', this.handleBarcodeScan)

    if (this.assemblingQueues.length > 0) {
      let foundObject
      if (this.$route.params.queue) {
        foundObject = this.assemblingQueues.find(item => {
          return parseInt(item.data.id) === parseInt(this.$route.params.queue)
        })

        if (foundObject) {
          this.handleQueueClick(foundObject)
        }
      }

      if (!foundObject && this.queues.length === 1) {
        this.handleQueueClick(this.queues[0])
      } else if (!foundObject && this.place) {
        this.handleClick({
          type: 'storage.queue.choose'
        })
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'addWarningNotification'
    ]),
    handleFilterSubmit (filter) {
      this.filter = filter
      this.isOpenFilter = false
      this.$emit('unblock')
    },
    toggleFilter () {
      this.isOpenFilter = !this.isOpenFilter
      this.$emit(this.isOpenFilter ? 'block' : 'unblock')
    },
    handleCloseFilter () {
      this.$emit('unblock')
    },
    handleQueueOpenClose () {
      this.isOpenQueue = !this.isOpenQueue
    },
    handleCloseAmount () {
      this.count = 1
      this.amountModal = false
      this.amountModalChanged = false
    },
    handleProductScan (barcode, item) {
      let products
      if (item) {
        products = [
          item
        ]
      }

      return this.updateCollection(barcode, products ?? null)
          .then(({ product, scannedCount, count, currentScannedCount }) => {
            let barcodeInstructions = product?.instructions.filter(function (instruction) {
              if (instruction.type === 'label-print') {
                instruction.data['count'] = currentScannedCount
                instruction.data['scannedCount'] = currentScannedCount

                return true
              }

              return false
            })

            if (!product) {
              this.addErrorNotification(`Product with barcode ${barcode.raw} is not found or maximum quantity is scanned!`)
              return
            }

            // if (product.instructions) {
            //   if (barcodeInstructions.length > 0) {
            //     this.executeInstructions(barcodeInstructions)
            //   }
            // }

            this.scannedBarcodes = [
              ...this.scannedBarcodes,
              { id: product.id, quantity: this.count, timestamp: new Date().toISOString() }
            ]
            this.product = product

            return this.executeInstructions(product.instructions, scannedCount)
                .then(() => {
                  if (scannedCount >= count) {
                    return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
                        .then(isOk => {
                          if (!isOk) {
                            this.revertChanges(this.product)
                          }

                          return isOk
                        })
                  }

                  if (this.fastMode) {
                    return false
                  }

                  return true
                })
                .then(isOk => {
                  if (isOk) {
                    return this.handleSubmit(this.scannedBarcodes)
                  }

                  return isOk
                })
                .then(isOk => {
                  if (this.product.scannedCount < this.product.count) {
                    this.maxAmount = this.product.count - this.product.scannedCount
                    this.amountModal = true
                  }

                  return isOk
                })
                .finally(() => {
                  this.count = 1
                  // this.scanItemEvent()
                })
          })
    },
    revertChanges (product) {
      const lastScan = this.scannedBarcodes[this.scannedBarcodes.length - 1]
      this.scannedBarcodes = this.scannedBarcodes.slice(0, this.scannedBarcodes.length - 1)

      this.product = {
        ...product,
        scannedCount: product.scannedCount - (lastScan?.quantity || 1)
      }

      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)

      this.dataObjects = {
        ...this.dataObjects,
        [collectionTaskName]: {
          ...this.dataObjects[collectionTaskName],
          data: this.dataObjects[collectionTaskName].data.map(p => {
            return p.id === product.id
                ? { ...this.product }
                : p
          })
        }
      }

      this.data = {
        ...this.data,
        objects: this.data.objects.map(x => {
          return x.type === 'collection' && x.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask
              ? this.dataObjects[collectionTaskName]
              : x
        })
      }

      return product
    },
    updateCollection (barcode, products) {
      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)

      const currentScannedCount = this.count ?? 1

      if (!products) {
        products = this.dataObjects[collectionTaskName].data.filter(product => {
          const barcodes = this.getBarcode(product)
          const scannedCount = product.scannedCount + this.count

          return barcodes.find(value => `${value}`.trim() === barcode.raw) && scannedCount <= product.count
        })
      }

      return Promise.resolve(products.length > 1)
          .then(shouldSelectProduct => {
            return shouldSelectProduct
                ? this.$refs.orderProductTasksModal.show(products)
                : products[0]
          })
          .then(selectedProduct => {
            let updatedProduct = null

            let scannedCount = 0
            let count = 0
            this.dataObjects = {
              ...this.dataObjects,
              [collectionTaskName]: {
                ...this.dataObjects[collectionTaskName],
                data: this.dataObjects[collectionTaskName].data.map(product => {
                  if (selectedProduct && selectedProduct.id === product.id) {
                    const scannedCount = product.scannedCount + this.count
                    product.scannedCount = scannedCount
                    updatedProduct = { ...product }
                  }

                  scannedCount += product.scannedCount
                  count += product._embedded.orderProduct.count

                  return product
                })
              }
            }

            this.data = {
              ...this.data,
              objects: this.data.objects.map(x => {
                return x.type === 'collection' && x.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask
                    ? this.dataObjects[collectionTaskName]
                    : x
              })
            }

            return { product: updatedProduct, scannedCount, count, currentScannedCount }
          })
    },
    handleOpenTasks () {
      const filter = [
        { field: 'queue', type: 'eq', value: this.queue },
        { field: 'state', type: 'eq', value: 'rejected' }
      ]

      this.$refs.assemblingTasksModal.open(filter, this.$t('Rejected Tasks'))
    },
    handleRequestDataClose () {
      this.$emit('stop-loading')
    },
    handleRequestData (data) {
      this.$refs.requestDataModal.close()

      this.data = {
        ...this.data,
        ...data
      }

      return this.handleSubmit(this.scannedBarcodes)
    },
    forceExecuteScanning () {
      return Promise.resolve(this.scannedBarcodes.length > 0)
          .then(hasBarcodes => {
            return hasBarcodes
                ? this.handleSubmit(this.scannedBarcodes)
                : Promise.resolve([])
          })
    },
    handleOpenRequestData () {
      this.$refs.requestDataModal.open(this.dataObjects['request-data'])
      this.$emit('start-loading')
    },
    handleAddAll () {
      this.count = this.maxAmount
      this.handleItemClick({ data: this.product })
    },
    handleBarcodeScan (e) {
      if (this.$refs.confirmModal?.isOpen) {
        this.$refs.confirmModal?.handleOk()
      }

      if (this.amountModal && e.key === 'Enter' && !this.amountModalChanged) {
        this.handleAddAll()
      }
    },
    getBarcode (product) {
      let barcodes = (product.instructions.find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode

      return Object.values(barcodes)
    },
    executeInstructions (instructions, scannedCount) {
      this.$emit('start-loading')
      const sortedInstructions = instructions.sort((a, b) => {
        if (a?.options?.priority && b?.options?.priority) {

          return b.options.priority - a.options.priority
        } else if (a?.options?.priority) {

          return -1
        } else if (b?.options?.priority) {

          return 1
        }

        return 0
      })

      const reducedInstructions = sortedInstructions.reduce((acc, instruction) => {
        if (instruction.options?.mode && instruction['options']['mode'] === 'deferred') {
          return acc
        }

        if (Array.isArray(instruction)) {
          acc = [...acc, ...instruction]
        } else {
          if (scannedCount === 1 || instruction.type !== 'message') {
            acc.push(instruction)
          }
        }

        return acc
      }, [])

      return this.instructionsService.execute(reducedInstructions)
          .then(result => {
            reducedInstructions.forEach(instruction => {
              console.log(instruction)
            })

            this.$emit('stop-loading')
            return result
          })
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    handleBarcode (barcode) {
      if (this.data.requiredData && this.data.requiredDataType) {
        const validations = {
          regexp: (value, validation) => {
            const regex = new RegExp(validation)
            return !regex.test(value.raw)
          },
          text: (value, validation) => {
            return value.raw !== validation
          }
        }

        if (!validations[this.data.requiredDataType]) {
          return this.addErrorNotification('Validation is not recognized!')
        }

        if (validations[this.data.requiredDataType](barcode, this.data.requiredData)) {
          return this.addErrorNotification('Barcode does not match scheme!')
        }
      }

      return this.handleSubmit(barcode)
    },
    createBarcodeData (barcode) {
      const raw = `${barcode}`.trim()
      const expr = /[A-z/]+\/([0-9A-z/]+)/

      if (!raw.match(expr)) {
        return {
          raw,
          value: raw,
          type: ''
        }
      }

      const data = raw
          .split('*')[0]
          .split('/')
          .reduce((acc, value) => {
            if (isNaN(Number(value)) && !acc.value) {
              acc.type += `${value}/`
            } else {
              acc.value += `${value}/`
            }

            return acc
          }, { value: '', type: '', raw })

      data.value = data.value.slice(0, data.value.length - 1)
      data.type = data.type.slice(0, data.type.length - 1)

      return data
    },
    handleSubmit (entity) {
      let data = this.reduceData(
          [
            'objects',
            'queue',
            'requiredData',
            'requiredDataType',
            'entity',
            'timestamp',
          ],
          {
            timestamp: new Date().toISOString(),
            entity,
            place: this.place?.id
          }
      )

      if (this.place) {
        data.place = this.place.id
      }

      if (entity && typeof entity === 'object' && entity.raw) {
        data.entity = entity.raw
      }

      const task = this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]

      if (task) {
        data.task = task.data.id
      }

      const supplyPlace = this.dataObjects[this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest_Place]

      if (supplyPlace) {
        data.supplyPlace = supplyPlace.data.id
      }

      if (this.dataObjects['callback-data']) {
        data = { ...this.dataObjects['callback-data'].data, ...data }
      }

      if (data.event === 'storage.warehouse.opened') {
        this.warehouse = entity.value
      }
      if (data.event === 'storage.place.opened' && this.warehouse) {
        data.warehouse = this.warehouse
        this.sourcePlace = entity.value
      }
      if (this.data.event === 'storage.assembling.scan.item' && this.warehouse && !this.isConfirmed) {
        data.warehouse = this.warehouse
        data.sourcePlace = this.sourcePlace
        this.itemToPack = entity.raw

      }
      if (this.isConfirmed) {
        data.warehouse = this.warehouse
        data.sourcePlace = this.sourcePlace
        data.entity = this.itemToPack
        data.quantity = this.count
        data.offer = this.product?.data?.id
      }

      if (this.entity) {
        data.entity = this.entity

        this.entity = null
      }

      this.isLoading = true
      this.$emit('start-loading')

      return this.$refs.entityUpdateModal.submit()
          .then(() => {
            return this.$service.assemblingQueue.process(this.queue, data, { filter: this.filter }, 'application/json+event')
          })
          .then(data => {
            this.data = data
            this.scannedBarcodes = []
            this.dataObjects = data.objects.reduce((acc, obj) => {
              const key = obj.type === 'collection'
                  ? `collection:${obj.entityClass}`
                  : obj.type

              acc[key] = obj

              return acc
            }, {})

            if (this.dataObjects.instruction && !this.dataObjects.instructions) {
              const instructions = this.dataObjects.instruction.data.find(x => x.type === 'instructions')

              if (instructions) {
                this.dataObjects.instructions = instructions
              }
            }

            if (this.dataObjects.instructions && this.dataObjects.instructions.options && this.dataObjects.instructions.options.run === 'immediately') {
              this.executeInstructions(this.dataObjects.instructions.data)
            }

            data.objects.forEach(obj => {
              if (obj.type.includes(this.$entities.Orderadmin_Products_Entity_Order)) {
                this.orderId = obj.data.id

                this.$router.push(`/workstation/packing/${this.place?.id}/${this.queue}/${this.orderId}`)
              }
            })
          })
          .catch(() => {
            if (this.data.event === 'storage.assembling.scan.item' && !this.warehouse) {
              this.revertChanges(this.product)
              if (!this.fastMode) {
                this.scannedBarcodes = []
              }
            }
          })
          .finally(() => {
            this.$emit('stop-loading')
            this.count = 1
            this.isLoading = false
            this.isConfirmed = false
            this.amountModal = false
          })
    },
    handleItemClick (item) {
      if (this.warehouse) {
        this.isConfirmed = true
        this.handleBarcode({ value: '', raw: '', type: '' })
        return
      }
      this.amountModal = false
      const barcodes = this.getBarcode(item.data)

      if (!barcodes || barcodes.length <= 0) {
        return this.handleProductScan(null, item.data)
        // this.addErrorNotification(`No barcodes in that product! Barcode value ${JSON.stringify(barcodes)}`)
        // return
      }

      const barcode = {
        value: barcodes[0],
        raw: barcodes[0]
      }

      this.handleProductScan(barcode)
    },
    scanItemEvent () {

      const data = this.reduceData(
          [
            'objects',
            'queue',
            'requiredData',
            'requiredDataType',
            'entity',
            'timestamp'
          ],
          {
            timestamp: new Date().toISOString(),
            place: this.place?.id,
          }
      )

      if (this.dataObjects[`collection:${this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask}`]) {
        data.entity = this.dataObjects[`collection:${this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask}`].data[0].id
        // const products = this.dataObjects[`collection:${this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask}`].data
        // if(this.fastMode && products.length == this.scannedBarcodes.length){
        //   data.entity = products.map(product =>{
        //     return {
        //       id:product.id,
        //       quantity:product.quantity,
        //       timestamp: new Date().toISOString(),
        //       }
        //   })
        // }else if(this.fastMode){
        //   return
        // }else {
        //   data.entity = this.product.id
        // }

      }

      const task = this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]

      if (task) {
        data.task = task.data.id
      }

      data.entityClass = this.$entities.Orderadmin_Products_Entity_Product_Offer

      const supplyPlace = this.dataObjects[this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest_Place]

      if (supplyPlace) {
        data.supplyPlace = supplyPlace.data.id
      }

      if (this.product._embedded.orderProduct.count) {
        data.count = this.product._embedded.orderProduct.count
      }

      data.event = 'storage.assembling.scan.item'

      this.$service.assemblingQueue.process(this.queue, data, { filter: this.filter }, 'application/json+event')
    },
    handleQueueClick (item) {
      if (this.isLoading) {
        this.addWarningNotification(`Loading in progress`)
        return
      }

      this.queue = item.data.id
      this.adapter = item.data.adapter
      this.data.entityClass = item.type
      this.data.event = item.event

      const fastMode = item.data.settings && item.data.settings['fast-mode'] !== undefined
          ? !!item.data.settings['fast-mode']
          : false

      this.$emit('fast-mode', fastMode)

      this.isLoading = true
      return this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
          .then(() => {
            if ((this.place?.id && this.$route.params.place != this.place?.id) || (this.queue && this.$route.params.queue != this.queue)) {
              this.$router.push(`/workstation/packing/${this.place?.id}/${this.queue}`)
            }

            this.$emit('show-barcode-field')

            this.isLoading = false
          })
          .then(() => {
            if ((this.$route.params.order)) {
              this.data.event = 'storage.order.opened'
              this.data.entityClass = 'Orderadmin\\Products\\Entity\\AbstractOrder'
              this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task] = null
              this.handleBarcode({ raw: this.$route.params.order, value: this.$route.params.order, type: '' })
            }
          })
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    openConfirmation () {
      this.areYouSure = true
    },
    handleOpenShipment (deliveryRequest) {
      let url = this.$route.path

      this.$router.push(`/workstation/labelling/regular/0/${deliveryRequest.data.id}?back=${url}`)
    },
    handleConfirm () {
      this.handleClick({ type: 'storage.queue.task.reject' })
    },
    handleClick (item) {
      const actionsByType = {
        'storage.service.opened': () => {
          let order = null
          if (this.data?.objects) {
            const result = this.data.objects.filter(e => {
              return e.type === this.$entities.Orderadmin_Products_Entity_Order
            })

            if (Array.isArray(result) && result.length === 0) {
              this.addErrorNotification(`Order not found!`)
            }

            order = result[0].data.id
          }

          this.$refs.AddSuppliesModal.show(this.place?.id, this.queue, this.dataObjects, order)
        },
        'storage.queue.choose': () => {
          this.queue = null
          this.product = null
          this.dataObjects = {}
          this.data = {
            place: this.place?.id,
            objects: [
              {
                type: 'message',
                data: {
                  position: 'bottom',
                  text: 'Choose queue'
                }
              },
              ...this.queues
            ]
          }
        },
        'storage.queue.task.print': () => {
          if (this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]) {
            return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
          }
        },
        'Orderadmin\\Storage\\Entity\\Assemblage\\Queue': this.handleQueueClick,
        'collection': this.handleItemClick,
        'storage.queue.task.reject': () => {
          this.data.event = 'storage.queue.task.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Assemblage_Task

          return this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.queue.task.order.product.reject': (item) => {
          this.data.event = 'storage.queue.task.order.product.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask
          this.entity = item.data

          return this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.assembling.complete.box': () => {
          this.data.event = 'storage.assembling.complete.box'
          return this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'Orderadmin\\Products\\Entity\\Product\\Offer': () => {
          this.product = item

          let counter = this.data.objects.filter(e => e.type === 'counter')
          this.count = counter[0].data.current
          this.maxAmount = counter[0].data.max
          this.ignoreMax = counter[0].data.ignoreMax
          this.amountModal = true

          // this.handleItemClick(item)
        },
        'storage.queue.task.list.boxes': (item) => {
          let deliveryRequest = null
          if (this.data?.objects) {
            const result = this.data.objects.filter(e => {
              return e.type === this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest
            })

            if (Array.isArray(result) && result.length === 0) {
              this.addErrorNotification(`The delivery request not found!`)
            }

            deliveryRequest = result[0].data
          }

          this.$refs.PlacesModal.show(deliveryRequest, item.data)
        }
      }

      if (actionsByType[item.type]) {
        return actionsByType[item.type](item)
      }

      if (!item.event) {
        return
      }

      this.data.entityClass = item.type
      this.data.event = item.event

      this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
    },
    reduceData (ignoredKeys = [], defaultValue = {}) {
      return Object.keys(this.data)
          .filter(key => !ignoredKeys.includes(key))
          .reduce((acc, key) => {
            return {
              ...acc,
              [key]: typeof this.data[key] === 'object'
                  ? this.data[key].id
                  : this.data[key]
            }
          }, { ...defaultValue })
    },
    handleAmount (e) {
      const value = Number(e)

      this.amountModalChanged = true

      if (value < 1) {
        this.count = 1
      } else {
        this.count = value
      }
    },
    handleIncrease () {
      this.count += 1
    },
    handleDecrease () {
      if (this.count <= 1) {
        return
      }

      this.count -= 1
    },
    onFocus () {
      this.$emit('block')
    },
    onFocusOut () {
      this.$emit('unblock')
    },
    extractBundle (data) {
      let bundleInstruction = null
      for (const instruction of data.instructions) {
        if (instruction.type === 'bundle') {
          bundleInstruction = instruction
          break
        }
      }

      return bundleInstruction?.bundleComponents ?? []
    }
  },
  handleBoxClick (item) {
    console.log(item)
  }
}
</script>
