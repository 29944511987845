<template>
  <q-card :flat="flat">
    <q-card-section class="row items-center q-pb-none">
      <div class=" col text-h6">
        {{ $t('Added Services') }}
      </div>
    </q-card-section>

    <q-card-section>
      <q-btn
        color="dark"
        outline
        size="md"
        class="q-ml-sm"
        icon="add"
        :label="$t('Add on one-time service')"
        no-caps
        @click="toggle"
      />
    </q-card-section>

    <q-card-section class="q-py-none">

      <q-tabs
          v-model="tab"
          dense
          class="text-dark"
      >
        <q-tab name="package" icon="inventory_2" no-caps label="Package" @click="handleCustomFiltersSubmit('package')"/>
        <q-tab name="shipping" icon="airplane_ticket" no-caps label="Label" @click="handleCustomFiltersSubmit('shipping')"/>
        <q-tab name="attachment" icon="attach_file" no-caps label="Attachment" @click="handleCustomFiltersSubmit('attachment')"/>
        <q-tab name="document" icon="description" no-caps label="Document" @click="handleCustomFiltersSubmit('document')"/>
        <q-tab name="custom" icon="crop_landscape" no-caps label="Custom" @click="handleCustomFiltersSubmit('custom')"/>

      </q-tabs>

    </q-card-section>

    <q-card-section class="q-ma-none q-pa-none">
      <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="services"
        :columns="columns"
        v-model:pagination="pagination"
        :filter="filter"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
            style="height: 91px"
          >
            <q-td
              key="number"
              :props="props"
              auto-width
            >
              <div class="text-subtitle1 text-weight-bold">
                {{ props.rowIndex + 1 }}
              </div>
            </q-td>


            <q-td
              key="type"
              :props="props"
              class="text-subtitle1"
            >
              <div v-if="props.row._embedded && props.row._embedded.supply">
                <div v-if="props.row._embedded.supply.type === 'attachment'">
                  <q-icon
                    name="attach_file"
                    size="md"
                  />
                </div>
                <div v-if="props.row._embedded.supply.type === 'package'">
                  <q-icon
                    name="inventory_2"
                    size="md"
                  />

                </div>
                <div v-if="props.row._embedded.supply.type === 'shipping'">
                  <q-icon
                    name="airplane_ticket"
                    size="md"
                  />
                </div>

                <div v-if="props.row._embedded.supply.type === 'document'">
                  <q-icon
                    name="description"
                    size="md"
                  />
                </div>

                  <!--                                <strong>{{ $t(props.row.type) }}</strong>-->
              </div>

              <div v-else>
                <q-icon
                  name="crop_landscape"
                  size="md"
                />
              </div>

            </q-td>

            <q-td
              key="name"
              :props="props"
              style="max-width: 50%;white-space: pre-wrap"
            >
              <div class="text-subtitle1 text-weight-bold" style="text-wrap: normal">
                {{ props.row.name }}
              </div>

              <strong
                v-if="props.row._embedded && props.row._embedded.supply && props.row._embedded.supply.type === 'package' && props.row._embedded.supply.dimensions"
                style="text-weight-bold: text-subtitle2"
              >
                  {{
                  `${props.row._embedded.supply.dimensions.x}/${props.row._embedded.supply.dimensions.y}/${props.row._embedded.supply.dimensions.z}`
                  }}
              </strong>
            </q-td>

            <q-td
              key="quantity"
              :props="props"
              style="width: 5%"
            >
              <div class="text-subtitle1 text-weight-bold">
                <editable-input-field
                        :value="props.row.count"
                        :update-function="newValue => handleChange(newValue,props.row , 'count')"
                        @change-catch-all="handleCatchAll"
                        :rules="[
                    (val) => /^[0-9]\d*$/.test(val),
                  ]"
                ></editable-input-field>
              </div>
            </q-td>

            <q-td
              key="price"
              :props="props"
              style="width: 5%"
            >
                <editable-input-field
                        :value="props.row.price"
                        :update-function="newValue => handleChange(newValue,props.row , 'price')"
                        @change-catch-all="handleCatchAll"
                        :rules="[
                          (val) => /^[0-9]\d*$/.test(val),
                        ]"
                ></editable-input-field>

            </q-td>

            <q-td
              key="total"
              :props="props"
            >
              <div class="text-subtitle1 text-weight-bold">
                  {{ handleTotal(props.row) }}
              </div>
            </q-td>

            <q-td
              key="orderProduct"
              :props="props"
            >
              <span v-if="props.row._embedded && props.row._embedded.orderProduct">
                {{props.row._embedded.orderProduct.id}}
              </span>
              <span v-else>
                {{'-'}}
              </span>
            </q-td>

            <q-td
              key="import"
              :props="props"
              auto-width
            >
              <div v-if="props.row._embedded && props.row._embedded.supply && props.row._embedded.supply.type === 'shipping'">
                <div v-if="props.row.eav && !props.row.eav['storage-services-document']">
                  <q-btn
                    label="Import PDF"
                    flat
                    color="positive"
                    @click="openFileInput"
                  />
                  <q-file
                    ref="fileInput"
                    accept=".pdf"
                    filled
                    class="d-none"
                    @update:model-value="handleUpload(props.row , $event)"
                  />
                </div>
                <div v-else>
                  <q-btn
                    class="q-mr-sm"
                    icon="download"
                    size="sm"
                    color="positive"
                    filled
                    @click="handleDownload(props.row)"
                  />
                  <q-btn
                    icon="delete"
                    size="sm"
                    color="negative"
                    filled
                    @click="handleDeletePDF(props.row)"
                  />
                </div>
              </div>
            </q-td>

            <q-td
              key="actions"
              :props="props"
              auto-width
            >
              <q-btn
                icon="close"
                flat
                color="negative"
                @click="handleDelete(props.row)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>
  </q-card>

  <service-modal ref="serviceModal" @on-submit="save"/>
</template>

<script>
import {buildQuery} from "@/apps/app/utils/query-utils";
import ServiceModal from "@/apps/app/components/modals/ServiceModal.vue";
import EditableInputField from "@/apps/app/components/delivery-services/EditableInputField.vue";

export default {
  name: 'AddedSuppliesTableOrder',
  emits: ['change-services', 'change-catch-all'],
  components: {
    EditableInputField,
    ServiceModal
  },
  props: {
    flat: {
      type: Boolean,
      default () {
        return false
      }
    },
    order: {
      type: Number,
    },
    services: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isExpanded: false,
      tab: '',
      currentSupply: null,
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('QTY'),
          name: 'quantity',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Total'),
          name: 'total',
          align: 'left'
        },
        {
          label: this.$t('Order Product'),
          name: 'orderProduct',
          align: 'left'
        },
        {
          label: 'PDF',
          name: 'import',
          align: 'center'
        },
        {
          label: 'Delete',
          name: 'actions',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      model: {},
      filters: [{ type: 'eq', field: 'state', value: 'active' }],
      extFilter: [],
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
    }
  },
  mounted() {
    if(this.order) {
      this.model = {
        state: 'active',
        order: this.order,
      }
    }

    this.extFilter = []

    // if (this.model.warehouse) {
    //   this.extFilter = [{ type: 'eq', field: 'warehouse', value: this.model.warehouse.id }]
    // } else {
    //
    // }

    this.filters = [{ field: 'order', type: 'eq', value: this.order}]

    this.onRequest({}, true)

  },
  computed: {
    width () {
      return '200px'
    }
  },
  watch: {
    isOpen (value) {
      this.isExpanded = value
    }
  },
  methods: {
    handleDownload (supply) {
      const fileUrl = supply.eav['storage-services-document']
      const link = document.createElement('a')
      link.href = fileUrl
      link.target = '_blank'
      link.setAttribute('download', '')
      link.click()
    },
    handleDeletePDF (supply) {
      this.$service.productsService.save({ eav: { 'storage-services-document': '' } }, supply.id)
        .then(() => {
          this.isOpen = false
          this.isOpen = true
          this.onRequest()
        })

    },
    handleUpload (supply, file) {
      if (file) {
        this.$service.productsService.upload(supply.id, file, 'eav-storage-services-document')
          .then(() => {
            this.addNotification('Successfully uploaded file!')
            this.isOpen = false
            this.isOpen = true
          }).catch(err => {
          this.addErrorNotification(err)
        })
      }
    },
    handleExpandCollapse () {
      this.isExpanded = !this.isExpanded
    },
    handleChange (result, item, field) {
      return this.$service.productsService.save({ [field]: result }, item.id)
        .then((item)=> {
          const index = this.services.findIndex(e=> e.id === item.id)
          if(index >= 0) {
            this.services[index][field] = result
          }
        })
    },
    openFileInput () {
      this.$refs.fileInput.$el.click()
    },
    handleDelete (row) {
      this.$service.productsService.save({ state: 'deleted' }, row.id)
        .then(() => {
          const services = this.services.filter(x => x.id !== row.id)
          this.$emit('change-services', services)
        })
    },
    toggleSupplies () {
      this.$refs.suppliesModal.open(this.extFilter)
    },
    save (service) {
      return this.$service.productsService.save({ ...service, order: this.order })
        .then(item => {
          const services =  [item, ...this.services]
          this.$emit('change-catch-all', true)
          this.$emit('change-services', services)
        })
    },
    toggle () {
      this.$refs.serviceModal.open()
        this.$emit('change-catch-all', false)
    },
    onRequest (data = {}, checkNew = false) {
      const query = buildQuery(this.pagination)
      query.filter = this.filters
      if(this.services.length > 0 && checkNew) {
        return
      }

      if(!this.customFilters) {
        this.filters = this.filters.filter(e=> e.type !== 'leftjoin')
        this.filters = this.filters.filter(e=> e.field !== 'type')
        query.filter = this.filters
      } else if(this.customFilters === 'custom') {
        this.filters = this.filters.filter(e=> e.type !== 'leftjoin')
        this.filters = this.filters.filter(e=> e.field !== 'type')
        query.filter.push({type: 'leftjoin', field: 'supply', alias: 'ssup' , parentAlias: 'pos' ,  conditionType: 'WITH', condition: 'pos.supply = ssup.id'})
        query.filter.push({type: 'notin' , field: 'type', values: ['attachment', 'package', 'shipping', 'document'] , alias: 'ssup'})
      } else {
        this.filters = this.filters.filter(e=> e.type !== 'leftjoin')
        this.filters = this.filters.filter(e=> e.field !== 'type')
        query.filter.push({type: 'leftjoin', field: 'supply', alias: 'ssup' , parentAlias: 'pos' ,  conditionType: 'WITH', condition: 'pos.supply = ssup.id'})
        query.filter.push({type: 'eq', field: 'type', value: this.customFilters , alias: 'ssup'})
      }


      return this.$service.productsService.getAll(query)
        .then(data => {
          this.pagination = {
            ...this.pagination,
            page: data.page,
            rowsNumber: data.totalItems
          }

          this.$emit('change-services', data.items)

          return data
        })
    },
    handleClick (data) {
      this.currentTab = 'supplyList'
      this.data = null

      setTimeout(() => {
          this.data = data
      })
    },
    handleCustomFiltersSubmit (filter) {
      if (this.customFilters === filter) {
        this.customFilters = ''
        this.tab = ''
        return this.onRequest({ pagination: { per_page: 250, page: 1 } })
      } else {
        this.customFilters = filter
        return this.onRequest({ pagination: { per_page: 250, page: 1 } })
      }
    },
    handleTotal (item) {
      return ((item.count || 0) * (item.price || 0)).toFixed(2)
    },
    disableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: false
      })
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    },
    handleCatchAll (value) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })
    }
  }
}
</script>
