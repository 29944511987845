<template>
  <div class="row" style="align-items: center">
    <q-list
        class="col rounded"
    >
      <q-item
          v-if="places && places.length > 1"
          class="block text-right q-item-no-border full-width q-item-no-padding"
          dense
      >
        <q-card class="col no-border" flat borderless>
          <q-card-section class="full-width row text-right no-wrap">
            <div class="col align-items-center text-left">
              <q-chip class="q-my-none text-center q-px-lg text-white" size="lg" square color="green-6">
                {{ $t('P') }}
              </q-chip>
            </div>
            <div class="col">
              <q-btn-group class="q-px-xs">
                <q-btn-dropdown
                    size="md"
                    flat
                    round
                    dense
                    no-icon
                    class="row justify-end"
                    dropdown-icon="more_horiz"
                >
                  <q-list>
                    <q-item clickable v-close-popup :disable="selectedItems.length === 0">
                      <q-item-section @click="handleItemsMove(selectedItems)">
                        <q-item-label>
                          {{ 'Move item' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="selectedItems.length === 0 || !(places.length > 1)">
                      <q-item-section @click="handleInstructions(selectedItems, place)">
                        <q-item-label>
                          {{ 'Instructions' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup>
                      <q-item-section @click="handleTab('product')">
                        <q-item-label>
                          {{ 'Add product' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="selectedItems.length === 0">
                      <q-item-section @click="handleMarkAsNotShipped(selectedItems)">
                        <q-item-label>
                          {{ 'Mark as not shipped' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="selectedItems.length === 0">
                      <q-item-section @click="handleDuplicate(selectedItems[0])">
                        <q-item-label>
                          {{ 'Duplicate product' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup :disable="selectedItems.length === 0">
                      <q-item-section @click="openConfirmation(selectedItems[0])">
                        <q-item-label>
                          {{ 'Delete item' }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </q-btn-group>
            </div>
          </q-card-section>

          <q-card-section>
            <q-table
                class="sticky-header-table"
                row-key="id"
                :rows-per-page-label="$t('Rows per page')"
                :rows="items"
                :columns="columns"
                v-model:pagination="pagination"
                selection="multiple"
                hide-bottom
                v-model:selected="selectedItems"
                binary-state-sort
                flat
            >
              <template v-slot:header-selection="props">
                <q-checkbox
                    size="md"
                    :model-value="selectedItems.length > 0"
                    color="amber-6"
                    @click="handleSelectAll(props)"
                />
              </template>


              <template v-slot:body="props">
                <q-tr
                    :props="props"
                    class="clickable"
                    :class="(props.row.state === 'return') ? 'bg-negative text-white' : ''"
                    v-if="!props.row._hidden"
                >
                  <td class="q-td text-left" style="width: 5%">
                    <q-checkbox
                        size="lg"
                        :model-value="!!selectedItems.find(({id}) => id === props.row.id)"
                        color="amber-6"
                        @click="handleSelectItems(props.row)"
                    />
                  </td>

                  <q-td
                      key="number"
                      :props="props"
                      auto-width
                  >
                    {{ props.rowIndex + 1 }}
                  </q-td>

                  <q-td
                      key="image"
                      :props="props"
                      style="width: 5%"
                  >
                    <div @click="showImageZoomDialog(props.row.image || fallbackImage)">
                      <img
                          :src="props.row.image || fallbackImage"
                          style="width: 60px; height: 60px; object-fit: contain;"
                          @error="onImageLoadFailure"
                      >
                    </div>

                    <q-dialog v-model="imageZoomDialog" persistent>
                      <div @click="closeImageZoomDialog" style="object-fit: contain;">
                        <img :src="zoomedSrc">
                      </div>
                    </q-dialog>
                  </q-td>

                  <q-td
                      key="name"
                      :props="props"
                      :style="places.length > 1 ? 'width: 50%; white-space: pre-wrap' : 'width: 75%; white-space: pre-wrap'"
                  >
                    <span class="row" v-html="highlightSubstring(props.row.name , props.row)"></span>
                  </q-td>

                  <q-td
                      key="quantity"
                      :props="props"
                      class="label-wrap"
                  >
                    <editable-input-field
                        label="Qty"
                        :value="props.row.count"
                        :update-function="newValue => handleQuantityUpdate(newValue, props.row)"
                        :disabled="disabled"
                        @change-catch-all="handleCloseEditItem"
                        :rules="[
                          (val) => /^[0-9]\d*$/.test(val)
                        ]"
                        :disable="isLoading"
                        style="min-width: 65px;"
                    ></editable-input-field>
                  </q-td>

                  <q-td
                      key="quantityToMove"
                      :props="props"
                      class="label-wrap"
                      style="white-space: pre-wrap !important;"
                  >
                    <div class="row" style="min-width: 115px;">
                      <div class="col">
                        <editable-input-field
                            label="Qty to move"
                            :value="props.row.quantityToMove"
                            :update-function="newValue => handleItemsMoveQuantity(newValue, props.row)"
                            :disabled="disabled"
                            @change-catch-all="handleCloseEditItem"
                            :rules="[
                              (val) => /^[0-9]\d*$/.test(val),
                              (val) => parseInt(val) <= props.row.count
                            ]"
                            :disable="isLoading"
                        ></editable-input-field>
                      </div>

                      <div class="col">
                        <q-btn
                            label="Ok"
                            color="green-6"
                            size="sm"
                            @click="moveToLast(props.row)"
                            :disable="isLoading"
                        />
                      </div>
                    </div>
                  </q-td>
                </q-tr>
                <q-tr
                    v-if="props.row.state === 'return'"
                    :props="props"
                    class="clickable"
                    :class="(props.row.state === 'return') ? 'bg-negative text-white' : ''"
                >
                  <q-td
                      key="number"
                      :props="props"
                      auto-width
                      colspan="7"
                  >
                    <editable-textarea-input
                        :value="props.row.comment"
                        :label="$t('Comment')"
                        class="text-white bg-white"
                        :outlined="true"
                        :input-label="$t('Comment')"
                        @change-catch-all="handleCloseEditItem"
                        :update-function="newValue => handleCommentUpdate(newValue, props.row)"
                    ></editable-textarea-input>
                  </q-td>
                </q-tr>
              </template>
            </q-table>

          </q-card-section>
        </q-card>

      </q-item>
    </q-list>

    <d-r-product
        ref="drProduct"
        :delivery="deliveryRequest"
        @change-catch="handleCloseEditItem"
        @submit="handleItemSubmit"
    />
  </div>

  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
          {{ $t('Are You Sure?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="negative"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Delete product')"
              @click="handleRemove()"
          />

          <q-btn
              textarea="center"
              :label="$t('Cancel')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

import DRProduct from '@/apps/app/components/products/DRProduct.vue'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'
import EditableTextareaInput from '@/apps/app/components/delivery-services/EditableTextareaInput.vue'

export default {
  name: 'ProductPool',
  emits: ['delete', 'dblclick', 'move-items', 'request-move-items', 'request-item-instructions', 'mark-shipped'],
  components: { EditableTextareaInput, EditableInputField, DRProduct },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    isBlocked: {
      type: Boolean,
      default () {
        return false
      }
    },
    deliveryRequest: {
      type: [String, Number],
      default () {
        return null
      }
    },
    places: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    place: {
      type: Object,
      default () {
        return {}
      }
    },
    itemToMove: {
      type: Object,
      default () {
        return null
      }
    },
    scanInput: {
      type: Object,
      default () {
        return null
      }
    },
  },
  mounted () {
    this.currentPlaces = this.places
    this.currentPlaces.sort((a, b) => b.priority - a.priority)

    if (this.place) {
      if (this.place.items) {
        this.items = this.place.items.map(e => {
          return { ...e, quantityToMove: 1 }
        })
      } else {
        this.items = this.place.items
      }
      if (this.place.dimensions) {
        this.width = this.place.dimensions.x
        this.length = this.place.dimensions.z
        this.height = this.place.dimensions.y
      }
      this.weight = this.place.weight
    }
    this.currentPlaces = this.places
  },
  data () {
    return {
      currentPlaces: null,
      itemsPool: null,
      items: [],
      selectedItems: [],
      itemToDelete: {},
      weight: null,
      height: null,
      width: null,
      length: null,
      areYouSure: false,
      fallbackImage: './assets/img/fallback-image/package.png',
      pagination: {
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      isLoading: false,
      imageZoomDialog: false,
      zoomedSrc: '',
    }
  },
  watch: {
    places: {
      handler (value) {
        this.currentPlaces = value

        let selectedPlaceNew = this.currentPlaces.filter(e => {
          if (e.eav && e.eav['delivery-services-request-place-is-pool']) {
            if (e.eav['delivery-services-request-place-is-pool'] === true) {
              return e
            }
          }
        })

        this.itemsPool = selectedPlaceNew[0]
        if (this.itemsPool && this.itemsPool.items) {
          this.items = this.itemsPool.items
          this.items = this.items.map(e => {
            return { ...e, quantityToMove: 1 }
          })
        }
      },
      deep: true
    },
    isBlocked: {
      handler (value) {
        this.isLoading = value
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    highlightSubstring () {
      return (text, item) => {
        if (!this.scanInput) {
          return text
        }

        if (this.scanInput.type === '') {
          const value = `(${this.scanInput.value})`
          const regex = new RegExp(value, 'gi')

          if (this.itemToMove && this.itemToMove.id === item.id) {
            return text.replace(regex, (match) => {
              return `<span class="bg-positive">${match}</span>`

            })
          } else if (this.itemToMove) {
            return text.replace(regex, (match) => {
              return `<span class="bg-yellow">${match}</span>`
            })
          } else {
            return text
          }
        } else {
          return text
        }
      }
    },
    columns () {
      return [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('QTY'),
          name: 'quantity',
          align: 'center'
        },
        this.places.length > 1 ?
            {
              label: this.$t('QTY to LB'),
              name: 'quantityToMove',
              align: 'left'
            } : {},
      ]
    },
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    showImageZoomDialog (src) {
      this.zoomedSrc = src
      this.imageZoomDialog = true
    },
    closeImageZoomDialog () {
      this.zoomedSrc = ''
      this.imageZoomDialog = false
    },
    handleDuplicate (item) {
      let itemChanges = {}
      Object.entries(item).forEach(([key, value]) => {
        if (value instanceof Object && value.id) {
          itemChanges[key] = value.id
        } else {
          itemChanges[key] = value
        }
      })

      itemChanges['place'] = this.place.id
      delete itemChanges.id
      delete itemChanges.created
      delete itemChanges.updated
      delete itemChanges.quantity

      itemChanges['deliveryRequest'] = this.place._embedded?.deliveryRequest?.id

      this.isLoading = true
      this.$service.deliveryServicePlaceItems.save(itemChanges).then(item => {
        itemChanges = {}
        this.$emit('moved')
        this.isLoading = false
      })
      return item

    },
    handleSelectItems (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems.push(item)
      }
    },
    handleCommentUpdate (text, item) {
      return this.$service.deliveryServicePlaceItems.save({ 'comment': text }, item.id)
    },
    handleQuantityUpdate (count, item) {
      item.count = parseInt(count)

      if (count >= 0) {
        return this.$service.deliveryServicePlaceItems.save({ 'count': count }, item.id)
      } else {
        return Promise.reject(new Error('Quantity to move should be positive value'))
      }
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    handleRemove () {
      return this.$service.deliveryServicePlaceItems.save({ state: 'deleted' }, this.itemToDelete.id)
          .then(() => {
            this.$emit('moved')
          })
      this.areYouSure = false
    },
    openConfirmation (item) {
      this.itemToDelete = item
      this.areYouSure = true
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleTab (tab) {
      if (tab === 'box') {
        const data = {
          deliveryRequest: this.deliveryRequest,
          dimensions: null,
          volume: null,
          weight: null
        }

        if (this.currentPlaces && this.currentPlaces.length <= 1) {
          data.priority = 1
        }

        this.isLoading = true
        return this.$service.deliveryServicePlace.save(data)
            .then(place => {
              this.currentPlaces = [place, ...this.places]
              this.$emit('change', this.currentPlaces)
              this.isLoading = false
            })
            .then(() => {
              if (this.currentPlaces.length <= 2) {
                let currentPlace = _.cloneDeep(this.place)
                currentPlace.eav = { ...currentPlace.eav, 'delivery-services-request-place-is-pool': true }

                //     {
                //     ...this.place,
                //     eav: {'delivery-services-request-place-is-pool' : true}
                // }
                this.isLoading = true
                this.$service.deliveryServicePlace.save({
                  priority: 0,
                  eav: { ['delivery-services-request-place-is-pool']: true }
                }, currentPlace.id).finally(() => {
                  this.isLoading = false
                })
              }
            })
      } else if (tab === 'product') {
        this.product = null
        this.$refs.drProduct.open()
      }
    },
    handleItemsMove (items = []) {
      this.$emit('request-move-items', {
        sourcePlace: this.itemsPool,
        items: items,
      })
    },
    handleInstructions (items = [], place) {
      if (!(this.places.length > 1)) {
        return
      }

      this.selectedItems = []

      this.$emit('request-item-instructions', {
        sourcePlace: place,
        targetPlace: this.place,
        items: items,
      })
    },
    handleItemSubmit (item, place, oldPlace) {
      this.$emit('move-items', {
        sourcePlace: oldPlace,
        targetPlace: place,
        items: [
          item,
        ],
      })
      this.$refs.drProduct.close()
      this.handleCloseItemModal()
    },
    handleCloseEditItem (value) {
      this.$emit('change-catch-all', value)
    },
    handleItemsMoveQuantity (quantity, item) {
      if (quantity >= 0) {
        this.quantityToMove = quantity
        item.quantityToMove = quantity
      }
    },
    updatePlaceDimensions (state, value, place) {
      if (Number(value) <= 0) {
        return
      }

      if (state === 'weight') {
        this.$service.deliveryServicePlace.save({ weight: value }, place.id)
      } else {
        if (!place.dimensions) {
          if (state === 'x') {
            this.width = value
          }
          if (state === 'y') {
            this.height = value
          }
          if (state === 'z') {
            this.length = value
          }
          if (this.width && this.length && this.height) {
            this.$service.deliveryServicePlace.save({
              dimensions: {
                x: this.width,
                y: this.height,
                z: this.length
              }
            }, place.id)
          }
        } else {
          let data = {
            ...place.dimensions,
          }
          data[state] = value
          this.$service.deliveryServicePlace.save({ dimensions: data }, place.id)
        }
      }
    },
    async handleMarkAsNotShipped (items = []) {
      this.$emit('mark-shipped', {
        items: items,
      })
    },
    moveToLast (item) {
      this.selectedItems = []

      this.$emit('move-items', {
        sourcePlace: this.place,
        targetPlace: this.places[0],
        items: [
          item,
        ],
      })
    },
    disableCatchAll () {
      setTimeout(() => {
        this.$eventBus.update('catchAll', {
          catchAll: false
        })
      }, 10)
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    }
  }
}
</script>

<style scoped>
.highlighted {
  background-color: yellow; /* Customize to your desired highlight style */
  font-weight: bold; /* Customize to your desired highlight style */
  /* Add more styles as needed */
}

.q-item-no-padding {
  padding: 0;
}
</style>